import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteMediaFailure,
  deleteMediaStart,
  deleteMediaSuccess,
  mediaFailure,
  mediaStart,
  mediaSuccess,
  postMediaFailure,
  postMediaStart,
  postMediaSuccess,
  updatemediaFailure,
  updatemediaStart,
  updatemediaSuccess,
} from "../Reducers/mediaReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const getmedia = createAsyncThunk(
  "auth",
  async ({ id, page, pageSize }, thunkAPI) => {
    try {
      thunkAPI.dispatch(mediaStart());
      const token = Cookies.get("token");
      const queryParams = {
        ...(pageSize && { size: pageSize }),

        ...(page && { page }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserMediaRoutes/GetUserMediaByUser/${id}`,
        {

          params: queryParams,
        }
      );
      const responseData = await response;

      thunkAPI.dispatch(mediaSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(mediaFailure(error.response.data.message));

      throw error;
    }
  }
);
export const postMedia = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postMediaStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}UserMediaRoutes/CreateUserMedia/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(postMediaSuccess(responseData));
      await thunkAPI.dispatch(getmedia({id}));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postMediaFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updateMedia = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updatemediaStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UsermediaRoutes/EditUsermedia/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updatemediaSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updatemediaFailure(error.response.data.message));

      throw error;
    }
  }
);
export const deleteMedia = createAsyncThunk("auth", async (id, thunkAPI) => {
  try {
    thunkAPI.dispatch(deleteMediaStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}UserMediaRoutes/SoftDeleteUserMedia/${id}`,

      config
    );
    const responseData = await response;

    thunkAPI.dispatch(deleteMediaSuccess(responseData));
    // await thunkAPI.dispatch(getmedia({}));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(deleteMediaFailure(error.response.data.message));

    throw error;
  }
});
