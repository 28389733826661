import { Button, Form, Input, message } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { deleteTimeline, updateTimeLine } from "../../../Redux/Actions/timeLine";
import { clearState } from "../../../Redux/Reducers/timeLineReducer";
import DeleteModal from "../../DeleteModal/DeleteModal";
import CustomCalender from "../CustomCalender";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const EditTimelineData = ({ editData, handleClose, handleModalOpen }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const { updateError, updateSuccess, loading } = useSelector(
    (state) => state.timeline
  );

  const onFinish = (values) => {
    const id = editData._id;
    values.Time = selectedDate;
    dispatch(updateTimeLine({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleeeNewDelete = () => {
    handleModalOpen(editData?._id);
    handleClose();
  };

  useEffect(() => {
    if (editData) {
      form.setFieldValue({
        EventName: editData.EventName,
        Description: editData.Description,
      });
      setSelectedDate(editData.Time ? moment(editData.Time).toDate() : null);
    }
  }, [editData, form]);

  useEffect(() => {
    if (updateError) {
      message.error(updateError);
      dispatch(clearState());
    }
    if (updateSuccess) {
      message.success(updateSuccess);
      handleClose();
      dispatch(clearState());
    }
  }, [updateSuccess, updateError]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={editData}
          form={form}
          autoComplete="off"
        >
          <div className="row uploadImageForm">
            <div className="col-sm-12 uploadHeading">
              <p>{t("editTimeline")}</p>
            </div>

            <div className="col-sm-12 datee">
              <Form.Item name="date" label={`${t("eventDate")}`}>
                <DatePicker
                  placeholderText={`${t("eventDate")}`}
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  isClearable
                  dateFormat="MMMM d, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  renderCustomHeader={CustomCalender}
                />
              </Form.Item>
            </div>

            <div className="col-sm-12 videoLink">
              <Form.Item
                name="EventName"
                label={`${t("eventName")}`}
                rules={[
                  {
                    required: true,
                    message: t("Please input the event name!"),
                  },
                ]}
              >
                <Input placeholder={`${t("eventName")}`} />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item name="Description" label={`${t("description")}`}>
                <Input.TextArea rows={4} placeholder={`${t("description")}`} />
              </Form.Item>
            </div>

            <div className="col-sm-12 DeleteButton">
              <Button onClick={handleeeNewDelete}>{t("delete")}</Button>

              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : t("save")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default EditTimelineData;
