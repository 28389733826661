import moment from 'moment'
import React from 'react'

const TimeLineDetail = ({handleClose, data}) => {
    return (
        <div className="container-fluid modaaals">
            <div className="row">
                <div className="col-sm-12">
                    <h1>{moment(data?.Time).format('DD MMMM YYYY')}</h1>
                    <h2>{data?.EventName}</h2>
                    <p>{data?.Description}</p>
                    {/* <div dangerouslySetInnerHTML={{ __html: data?.Description }} /> */}
                </div>
            </div>
        </div>
    )
}

export default TimeLineDetail
