import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  data: {},
};

const tributeSettingSlice = createSlice({
  name: "tributeSetting",
  initialState,
  reducers: {
    tributeSettingStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    tributeSettingSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    tributeSettingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateTributeSettingStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updateTributeSettingSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.editSuccess = action.payload.data.message;
    },
    updateTributeSettingFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editError = null;
      state.editSuccess = null;
    },
  },
});

export const {
  tributeSettingStart,
  tributeSettingSuccess,
  tributeSettingFailure,
  updateTributeSettingStart,
  updateTributeSettingSuccess,
  updateTributeSettingFailure,
  clearState,
} = tributeSettingSlice.actions;

export default tributeSettingSlice.reducer;
