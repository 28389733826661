import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
};

const editMyProfileSlice = createSlice({
    name: "editMyProfile",
    initialState,
    reducers: {
        editMyProfileStart(state, payload) {
            state.loading = true;
            state.error = null;
        },
        editMyProfileSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.data;
            state.success = action.payload.data.message;
        },
        editMyProfileFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
        },
    },
});

export const {
    editMyProfileStart,
    editMyProfileSuccess,
    editMyProfileFailure,
    clearState,
} = editMyProfileSlice.actions;

export default editMyProfileSlice.reducer;
