import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Navigation from '../Components/QRDetail/Navigation'
import Tribute from '../Components/QRDetail/Tributees/Tribute'
import FooterNav from '../Components/QRDetail/Footeer/FooterNav'
import TributeHeader from '../Components/QRDetail/Tributees/TributeHeader'

function Tributte() {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className='col-sm-3 sideebarr'>
                        <Sidebar />
                    </div>    

                    <div className='col-sm-9 sideebarr'>
                        <TributeHeader />
                        <Navigation />
                        <Tribute />
                        <FooterNav />
                    </div>   
                </div>    
            </div>
        </Fragment>
    )
}

export default Tributte