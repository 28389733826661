import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Image } from "antd";
import ediImage from '../../Assets/edit.png'
import { getMyDetails } from "../../Redux/Actions/auth";
import { Modal } from "react-bootstrap";
import ProfileForm from "./ProfileForm";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Navigation = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { data } = useSelector((state) => state.user);
  const isAuthenticated = Cookies.get("isAuthenticated")
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getMyDetails({ id }));
  }, [dispatch, id]);

  return (
    <Fragment>
      <div className="container-fluid qrr">
        <div className="row noMArr naaavvigationnn">
          {data?.Name ? (
            <div className="col-sm-12 profileImageess">
              <Image src={data?.CoverImage} className="coverPhoto" />
              <div className="row nooMarrgin">
                <div className="col-sm-3">
                  <div className="profieImagwe">
                    <Image src={data?.ProfilePic} />
                    {/* <img  /> */}
                  </div>
                </div>
                <div className="col-sm-9 prooofDEtttail">
                  <div>
                    <p className="deadPersonName">{data?.Name}</p>
                    <p className="deadPersonDate">
                      {moment(data?.DOB).format("MMM Do YYYY")} -{" "}
                      {moment(data?.DOD).format("MMM Do YYYY")}
                    </p>
                  </div>
                  {isAuthenticated == "true" && (
                    <Button className="editBTn" onClick={handleShow}>
                      <img src={ediImage} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="col-sm-12 profileImageess formRight"
                style={{ textAlign: "center" }}
              >
                <p>{t("no_profile_data")}</p>
                {isAuthenticated == "true" && (
                  <Button onClick={handleShow}>{t("add_profile_data")}</Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="EditProfile">
        <Modal.Header closeButton></Modal.Header>

        <ProfileForm handleClose={handleClose} />
      </Modal>
    </Fragment>
  );
};

export default Navigation;
