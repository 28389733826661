import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  isAuthenticated: false,
  isAuthenticatedLoading: false,
  allowReviewAfterEndDate: true,
  AllowToUploadPicture: false,
  data: {},
  token: null,
  qrData: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLoginStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    authLoginSuccess(state, action) {
      state.loading = false;
      state.token = action.payload.data.token;

      state.success = action.payload.data.message;
    },
    authLoginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.allowReviewAfterEndDate = action.payload.data.allowReviewAfterEndDate;
      state.AllowToUploadPicture = action.payload.data.AllowToUploadPicture;
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfileStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updateProfileSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.data.message;
    },
    updateProfileFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    changePasswordStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    changePasswordSuccess(state, action) {
      state.loading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.passwordError = action.payload;
    },

    qrAuthenticationStart(state, payload) {
      state.loading = true;
      state.isAuthenticated = false;
      state.isAuthenticatedLoading = true;
    },

    qrAuthenticationSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = action.payload.data.isAuthenticated;
      Cookies.set("isAuthenticated", action.payload.data.isAuthenticated);
      state.isAuthenticatedLoading = false;
    },
    qrAuthenticationFailure(state, action) {
      state.loading = false;
      state.isAuthenticatedLoading = false;
      state.isAuthenticated = false;
    },

    getQrDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.isAuthenticatedLoading = true;
    },

    getQrDetailsSuccess(state, action) {
      state.loading = false;
      state.qrData = action.payload.data.data;
      state.isAuthenticatedLoading = false;
    },
    getQrDetailsFailure(state, action) {
      state.loading = false;
      state.isAuthenticatedLoading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.isAuthenticatedLoading = false;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
      state.AllowToUploadPicture = false;
      state.allowReviewAfterEndDate = false;
    },
  },
});

export const {
  authLoginStart,
  authLoginSuccess,
  authLoginFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  qrAuthenticationStart,
  qrAuthenticationSuccess,
  qrAuthenticationFailure,
  getQrDetailsFailure,
  getQrDetailsSuccess,
  getQrDetailsStart,
  clearState,
} = authSlice.actions;

export default authSlice.reducer;
