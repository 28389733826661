import React, { Fragment, useEffect } from 'react'
import logo from '../../Assets/logo.png'
import { Button, Form, Input, message } from 'antd'
import frwd from '../../Assets/rightArrow.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { forgetPassword } from '../../Redux/Actions/forgetPassword'
import { clearState } from '../../Redux/Reducers/forgetPasswordReducer'
import Cookies from 'js-cookie';
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { error, success, loading } = useSelector(
    (state) => state.forgetPassword
  );

  const Dispatch = useDispatch();
  const onFinish = async (values) => {
    await Dispatch(forgetPassword({ values }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      Dispatch(clearState());
    }
    if (success) {
      message.success(success);
      Cookies.remove("token");
      navigate("/");
      Dispatch(clearState());
    }
  }, [error, success, Dispatch]);

  const validatePassword = (_, value) => {
    if (value && value.length < 6) {
      return Promise.reject(t("Password must be at least 6 characters long."));
    }
    return Promise.resolve();
  };

  return (
    <Fragment>
      <div className="container-fluid loginForm ">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 cennn">
              <img src={logo} alt="asd" />
            </div>

            <div className="col-sm-12 passreset newPasss">
              <Form.Item
                label={t("Password")}
                name="firstPassword"
                rules={[
                  {
                    required: true,
                    message: t("Please input your password!"),
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password placeholder={t("Password")} />
              </Form.Item>
            </div>
            <div className="col-sm-12 passreset ">
              <Form.Item
                label={t("Confirm Password")}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: t("Please input your confirm password!"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("firstPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t("The two passwords do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t("Confirm Password")} />
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Button htmlType="submit" disabled={loading} type="primary">
                {" "}
                {loading ? <Loader /> : t("Reset")} <img src={frwd} alt="asd" />
              </Button>
            </div>
            <div className="col-sm-12 cennn">
              <p>
                {t("Do you want to Login?")}{" "}
                <span
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/")}
                >
                  {t("Login")}
                </span>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default NewPasswordForm
