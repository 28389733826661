import React, { Fragment, useEffect } from "react";
import Headers from "../Components/Headerr/Headers";
import Navigation from "../Components/QRDetail/Navigation";
import Bio from "../Components/QRDetail/Bio";
import FooterNav from "../Components/QRDetail/Footeer/FooterNav";
import BioHeader from "../Components/QRDetail/BioHeader";
import { useParams } from "react-router-dom";

function BioPage() {
  const { id } = useParams();
  useEffect(() => {
    localStorage.setItem("ids", id);
  }, []);
  return (
    <Fragment>
      <div class="container-fluid main">
        <div class="row">
          <div className="col-sm-3 sideebarr"></div>

          <div className="col-sm-9 sideebarr">
            <BioHeader />
            <Navigation />
            <Bio />
            <FooterNav />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BioPage;
