import React, { Fragment } from 'react'
import { RiEditBoxFill } from "react-icons/ri";
import { IoChevronForwardOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const TributeLine = () => {
  const { data, allowReviewAfterEndDate } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/pages/tributes/${id}`, {
      state: {
        isOpen: true,
      },
    });
  };
  return (
    <Fragment>
        <div className="container-fluid tributeNavigate">
      {allowReviewAfterEndDate && (
          <div className="row">
            <div className="col-sm-12" onClick={() => handleNavigate(data?.QrId)}>
              <RiEditBoxFill color="#fff" size={24} />
              <p>
                {t("writeTribute")} {data?.Name ? data?.Name : "this user"}
              </p>
              <IoChevronForwardOutline color="#fff" size={24} />
            </div>
          </div>
      )}
        </div>
    </Fragment>
  );
};

export default TributeLine
