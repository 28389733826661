import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Navigation from '../Components/QRDetail/Navigation'
import Photoss from '../Components/QRDetail/UploadImageVideo/Photoss'
import FooterNav from '../Components/QRDetail/Footeer/FooterNav'
import PhotosHeader from '../Components/QRDetail/UploadImageVideo/PhotosHeader'

function PhotossPage() {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className='col-sm-3 sideebarr'>
                        <Sidebar />
                    </div>    

                    <div className='col-sm-9 sideebarr'>
                        <PhotosHeader />
                        <Navigation />
                        <Photoss />
                        <FooterNav />
                    </div>   
                </div>    
            </div>
        </Fragment>
    )
}

export default PhotossPage