import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  postData: null,
  deleteError: null,
  deleteSuccess: null,
  postError: null,
  postSuccess: null,
  updateError: null,
  updateSuccess: null,
  imageLoading: false,
  data: [],
  singleData: {},
};

const tributeSlice = createSlice({
  name: "tribute",
  initialState,
  reducers: {
    tributeStart(state) {
      state.loading = true;
      state.error = null;
    },

    tributeSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.result;
      state.success = action.payload.data.message;
    },
    tributeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updatetributeStart(state) {
      state.loading = true;
      state.updateError = null;
    },

    updatetributeSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.updateSuccess = action.payload.data.message;
    },
    updatetributeFailure(state, action) {
      state.loading = false;
      state.updateError = action.payload;
    },
    postTributeStart(state) {
      state.loading = true;
      state.postError = null;
    },

    postTributeSuccess(state, action) {
      state.loading = false;
      state.postSuccess = action.payload.data.message;
      state.postData = action.payload.data.data;
    },
    postTributeFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
    },
    postTributeImageStart(state) {
      state.imageLoading = true;
      state.postError = null;
    },

    postTributeImageSuccess(state, action) {
      state.imageLoading = false;
      state.imageSuccess = action.payload.data.message;
    },
    postTributeImageFailure(state, action) {
      state.imageLoading = false;
      state.postError = action.payload;
    },
    deletetributeStart(state) {
      state.loading = true;
      state.deleteError = null;
    },

    deletetributeSuccess(state, action) {
      state.loading = false;
      state.deleteSuccess = action.payload.data.message;
    },
    deletetributeFailure(state, action) {
      state.loading = false;
      state.deleteError = action.payload;
    },
    singleTributeStart(state) {
      state.loading = true;
      state.error = null;
    },

    singleTributeSuccess(state, action) {
      state.loading = false;
      state.singleData = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    singleTributeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteTributeImageStart(state) {
      state.loading = true;
      state.error = null;
    },

    deleteTributeImageSuccess(state, action) {
      state.loading = false;
      state.singleData = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    deleteTributeImageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.deleteError = null;
      state.deleteSuccess = null;
      state.postError = null;
      state.postSuccess = null;
      state.updateError = null;
      state.updateSuccess = null;
      state.imageLoading = false;
      state.loading = false;
    },
    clearPostData: (state) => {
      state.postData = null;
    },
  },
});

export const {
  tributeStart,
  tributeSuccess,
  tributeFailure,
  updatetributeStart,
  updatetributeSuccess,
  updatetributeFailure,
  deletetributeStart,
  deletetributeSuccess,
  deletetributeFailure,
  postTributeStart,
  postTributeSuccess,
  postTributeFailure,
  postTributeImageStart,
  postTributeImageSuccess,
  postTributeImageFailure,
  deleteTributeImageFailure,
  deleteTributeImageSuccess,
  deleteTributeImageStart,
  singleTributeFailure,
  singleTributeSuccess,
  singleTributeStart,
  clearState,
  clearPostData,
} = tributeSlice.actions;

export default tributeSlice.reducer;
