import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { IoRoseOutline, IoDocumentText, IoChatbubblesOutline, IoShareOutline, IoQrCodeOutline, IoArrowBack } from 'react-icons/io5';
import { IoMdPhotos } from "react-icons/io";
import { MdOutlineTimeline } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Button } from 'antd';
import { useTranslation } from "react-i18next";

const OnboardingSteps = ({ handleClose }) => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);

  const [isEndOfSwiper, setIsEndOfSwiper] = useState(false);
  const [flag, setFlag] = useState(false);

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
      setFlag(!flag);
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
      setFlag(!flag);
    }
  };

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper;
    if (swiperInstance) {
      setIsEndOfSwiper(swiperInstance.isEnd);
    }
  }, [flag]);

  return (
    <Fragment>
      <div className="container-fluid onboardingSwpierr">
        <div className="row">
          <div className="col-sm-12">
            <Swiper
              ref={swiperRef}
              pagination={true}
              modules={[Pagination]}
              className="mySwiper"
              loop={false}
            >
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12 welcome">
                    <p>{t("welcome")}!</p>
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoRoseOutline size={54} color="#87562E" />
                    <p>{t("sorryForLoss")}</p>

                    <p>{t("uploadPhotos")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoDocumentText size={54} color="#87562E" />
                    <p>{t("uploadnewphotsonboard")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoMdPhotos size={54} color="#87562E" />
                    <p>{t("uploadPhotosAndVideos")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <MdOutlineTimeline size={54} color="#87562E" />
                    <p>{ t("addEvents") } </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoChatbubblesOutline size={54} color="#87562E" />
                    <p>{t("collectTributes")}</p>
                    <ol>
                      <li>
                        <p>{t("enableTributes")}</p>
                      </li>
                      <li>
                        <p>{t("sharePage")}</p>
                      </li>
                      <li>
                        <p>{t("printQRCode")}</p>
                      </li>
                    </ol>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoShareOutline size={54} color="#87562E" />
                    <p>{t("shareMemorialPage")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoQrCodeOutline size={54} color="#87562E" />
                    <p>{t("attachQRPlaque")}</p>

                    <ol>
                      <li>
                        <p>{t("identifyLocation")}</p>
                      </li>
                      <li>
                        <p>
                          <p>{t("prepareSurface")}</p>
                        </p>
                      </li>
                      <li>
                        <p>{t("removeBacking")}</p>
                      </li>
                      <li>
                        <p>{t("ensureLevel")}</p>
                      </li>
                    </ol>
                    <p>{t("qrPlaqueDetails")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoQrCodeOutline size={54} color="#87562E" />
                    <p>{t("qrPlaqueVisitors")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <IoShareOutline size={54} color="#87562E" />
                    <p>{t("shareMemorialPage1")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row">
                  <div className="col-sm-12">
                    <IoArrowBack
                      size={34}
                      color="#000"
                      className="swiper-button-prev"
                      onClick={handlePrevClick}
                    />
                  </div>
                  <div className="col-sm-12 onboard">
                    <FaRegQuestionCircle size={54} color="#87562E" />
                    <p>{t("viewTips")}</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <Button
            className="swiper-button-next swipereBtn"
            onClick={isEndOfSwiper ? handleClose : handleNextClick}
          >
            {isEndOfSwiper ? `${t("close")}` : `${t("next")}`}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default OnboardingSteps;