import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  deleteError: null,
  deleteSuccess: null,
  postError: null,
  postSuccess: null,
  updateError: null,
  updateSuccess: null,
  data: [],
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    mediaStart(state) {
      state.loading = true;
      state.error = null;
    },

    mediaSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.result;
      state.success = action.payload.data.message;
    },
    mediaFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updatemediaStart(state) {
      state.loading = true;
      state.updateError = null;
    },

    updatemediaSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.updateSuccess = action.payload.data.message;
    },
    updatemediaFailure(state, action) {
      state.loading = false;
      state.updateError = action.payload;
    },
    postMediaStart(state) {
      state.loading = true;
      state.postError = null;
    },

    postMediaSuccess(state, action) {
      state.loading = false;
      state.postSuccess = action.payload.data.message;
    },
    postMediaFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
    },
    deleteMediaStart(state) {
      state.loading = true;
      state.deleteError = null;
    },

    deleteMediaSuccess(state, action) {
      state.loading = false;
      state.deleteSuccess = action.payload.data.message;
    },
    deleteMediaFailure(state, action) {
      state.loading = false;
      state.deleteError = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.deleteError = null;
      state.deleteSuccess = null;
      state.postError = null;
      state.postSuccess = null;
      state.updateError = null;
      state.updateSuccess = null;
      state.loading = false;
    },
  },
});

export const {
  mediaStart,
  mediaSuccess,
  mediaFailure,
  updatemediaStart,
  updatemediaSuccess,
  updatemediaFailure,
  deleteMediaStart,
  deleteMediaSuccess,
  deleteMediaFailure,
  postMediaStart,
  postMediaSuccess,
  postMediaFailure,
  clearState,
} = mediaSlice.actions;

export default mediaSlice.reducer;
