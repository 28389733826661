import React from "react";
import CheckProfile from "./CheckProfile";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const CheckQrDeatail = ({ qrData, qrDetail, profileData }) => {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate("/");
  };
  return (
    <>
      {qrData && qrData?.IsActive && !qrDetail?.UserDetail?.Name ? (
        <>
          <h2>Nothing is Uploaded Yet! 😊</h2>
          <h3>Be Patient</h3>
          <Button className="registerBtn" onClick={handleRegister}>
            Login
          </Button>
        </>
      ) : (
        <CheckProfile profileData={profileData} isActive={qrData?.IsActive} />
      )}
    </>
  );
};

export default CheckQrDeatail;
