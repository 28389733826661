import React from "react";

const CustomCalender = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Function to generate an array of years from min to max
  const generateYears = (min, max) => {
    const years = [];
    for (let year = min; year <= max; year++) {
      years.push(year);
    }
    return years;
  };

  // Get current year
  const currentYear = new Date().getFullYear();

  // Generate an array of years spanning a wider range
  const years = generateYears(currentYear - 100, currentYear + 0);

  return (
    <div className="custom-datepicker-header">
      {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {"<"}
      </button> */}
      <select
        value={date.getMonth()}
        onChange={(e) => changeMonth(parseInt(e.target.value))}
      >
        {months.map((month, index) => (
          <option key={month} value={index}>
            {month}
          </option>
        ))}
      </select>
      <select
        value={date.getFullYear()}
        onChange={(e) => changeYear(e.target.value)}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {">"}
      </button> */}
    </div>
  );
};

export default CustomCalender;
