import { createAsyncThunk } from "@reduxjs/toolkit";
import { forgetPasswordStart, forgetPasswordSuccess, forgetPasswordFailure } from "../Reducers/forgetPasswordReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const forgetPassword = createAsyncThunk(
    "forgetPassword",
    async ({ values }, thunkAPI) => {
        try {
          thunkAPI.dispatch(forgetPasswordStart());

          const token = Cookies.get("token"); // Retrieve the token from the cookie

          const config = {
            headers: {
              Authorization: token,
            },
          };

          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}UserInfoRoutes//ResetPassword/${token}`,
            values
          );

          thunkAPI.dispatch(forgetPasswordSuccess(response.data));
          Cookies.remove("token");
          return response.data;
        } catch (error) {
          thunkAPI.dispatch(forgetPasswordFailure(error.response.data.message)); // Dispatch the failure action
          return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
        }
    }
);