import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  data: [],
};

const qrListSlice = createSlice({
  name: "qrList",
  initialState,
  reducers: {
    qrListStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    qrListSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    qrListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearQrState: (state) => {
      state.error = null;
      state.success = null;
      state.data = null;
    },
  },
});

export const {
  qrListStart,
  qrListSuccess,
  qrListFailure,

  clearQrState,
} = qrListSlice.actions;

export default qrListSlice.reducer;
