import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cityListFailure,
  cityListStart,
  cityListSuccess,
  deathCityListFailure,
  deathCityListStart,
  deathCityListSuccess,
} from "../Reducers/cityListReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const cityList = createAsyncThunk(
  "auth",
  async ({ size, name }, thunkAPI) => {
    try {
      thunkAPI.dispatch(cityListStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const queryParams = {
        ...(size && { size }),

        ...(name && { name }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}CityRoutes/GetCityListing`,
        { config, params: queryParams }
      );
      const responseData = await response;

      thunkAPI.dispatch(cityListSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(cityListFailure(error.response.data.message));

      throw error;
    }
  }
);
export const deathCityList = createAsyncThunk(
  "auth",
  async ({ citySize, cityName }, thunkAPI) => {
    try {
      thunkAPI.dispatch(deathCityListStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const queryParams = {
        ...(citySize && { size: citySize }),

        ...(cityName && { name: cityName }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}CityRoutes/GetCityListing`,
        { config, params: queryParams }
      );
      const responseData = await response;

      thunkAPI.dispatch(deathCityListSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(deathCityListFailure(error.response.data.message));

      throw error;
    }
  }
);
