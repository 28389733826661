import { Button, Form, Image, Input, Upload, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import AddTributeForm from "./AddTributeForm";
import TributeSettingForm from "./TributeSettingForm";
import EditTributeForm from "./EditTributeForm";
import DeleteModal from "../../DeleteModal/DeleteModal";
import {
  clearPostData,
  clearState,
} from "../../../Redux/Reducers/tributeReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTribute,
  getTribute,
  gettribute,
  postTributeImage,
} from "../../../Redux/Actions/tributes";
import moment from "moment";
import Pagination from "../../Pagination/Pagination";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import edit from "../../../Assets/edit.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import AddImageInTribute from "./AddImageInTribute";
import { getTributeSetting } from "../../../Redux/Actions/tributeSetting";

const Tribute = () => {
  const { AllowToUploadPicture } = useSelector((state) => state.user);
  const tributeSettings = useSelector((state) => state.tributeSetting.data);
  const { postData } = useSelector((state) => state.tribute);
  const [imageFields, setImageFields] = useState([{ id: 1 }]);
  const [showYes, setShowYes] = useState(false);
  const { state } = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const [showImage, setImageShow] = useState(false);
  const handleShowImage = () => setImageShow(true);
  const handleCloseImage = () => {
    dispatch(getTribute({ id, page, pageSize }));
    setImageShow(false);
  };
  const [showSetting, setShowSetting] = useState(false);
  const handleShowSetting = () => setShowSetting(true);
  const handleCloseSetting = () => setShowSetting(false);

  const [tributeSetting, setTributeSetting] = useState(false);
  const handleTributeTrue = () => {
    setTributeSetting(true);
  };
  const handleTributeFalse = () => {
    setTributeSetting(false);
  };

  const [tributeImageUpload, setTributeImageUpload] = useState(true);
  const handleTributeImageUpload = () => {
    setTributeImageUpload(!tributeImageUpload);
  };
  console.log(
    tributeSettings,
    "<=================================tributeSetting"
  );

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);
  const isAuthenticated = Cookies.get("isAuthenticated");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { data } = useSelector((state) => state.tribute);
  const { allowReviewAfterEndDate } = useSelector((state) => state.user);

  const [showEdit, setShowEdit] = useState(false);
  const handleShowEdit = (id) => {
    setId(id);
    setEditData(data);
    setShowEdit(true);
  };
  const handleCloseEdit = () => {
    dispatch(getTribute({ id, page, pageSize }));

    setShowEdit(false);
  };
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [ids, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const { deleteError, deleteSuccess } = useSelector((state) => state.tribute);
  const getInitials = (name) => {
    return name?.charAt(0).toUpperCase() || "";
  };

  const handleDelete = async (ids) => {
    await dispatch(deleteTribute(ids));
    await dispatch(getTribute({ id, page, pageSize }));
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    dispatch(getTribute({ id, page, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    if (deleteError) {
      message.error(deleteError);
      dispatch(clearState());
    }
    if (deleteSuccess) {
      message.success(deleteSuccess);
      dispatch(clearState());
    }
  }, [deleteError, deleteSuccess]);
  // useEffect(() => {
  //   dispatch(clearPostData());
  // }, [show]);
  useEffect(() => {
    if (state && state.isOpen) {
      setShow(true);
      state.isOpen = false;
    }
  }, [state]);

  useEffect(() => {
    dispatch(getTributeSetting(id));
  }, []);
  return (
    <Fragment>
      <div className="container-fluid qrr myTributess myytributesnon timelineeee">
        <div className="row noMArr fform">
          <div className="col-sm-12 topAPd widthSeventy">
            <div className="row mrginNo">
              <div className="col-sm-6 settingBTn">
                {isAuthenticated == "true" && (
                  <Button onClick={handleShowSetting}>
                    {t("allow_others_to_write_tribute")} ?
                    {
                      tributeSettings.AllowSettings === "Always Allow"
                        ? t("yes")
                        : tributeSettings.AllowSettings ===
                          "Allow Between Dates"
                        ? tributeSettings.StartDate &&
                          tributeSettings.EndDate &&
                          new Date() >= new Date(tributeSettings.StartDate) &&
                          new Date() <= new Date(tributeSettings.EndDate)
                          ? t("yes")
                          : t("no") // Show nothing if dates are not within the range
                        : tributeSettings.AllowSettings === "Never Allow"
                        ? t("no")
                        : null // Default to showing nothing
                    }
                  </Button>
                )}
              </div>
              <div className="col-sm-6 formRight timelineTable">
                {isAuthenticated == "true" && (
                  <Button onClick={handleShow}>{t("add")}</Button>
                )}
              </div>
              {/* <div className="col-sm-12 tributeDetaisl"> */}
              {data?.items?.map((item) => (
                <div className="row inTribute">
                  {/* myTributeRow */}
                  <div className="col-sm-12 tributePageListing">
                    <div className="row hassan">
                      <div className="col-sm-1 ">
                        <div className="leterCircle">
                          <p>{getInitials(item?.TributeName)}</p>
                        </div>
                      </div>

                      <div className="col-sm-11">
                        <div className="myTributeRow">
                          <div className="profiletribute">
                            {isAuthenticated == "true" && (
                              <div className="editandDelete">
                                {item?.canEdit && (
                                  <Button
                                    onClick={() => handleShowEdit(item._id)}
                                  >
                                    <img src={edit} alt="edit" />
                                  </Button>
                                )}
                                <Button
                                  onClick={() => handleModalOpen(item?._id)}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            )}
                            {/* {
                            item?.QrTributesData?.QrProfileData?.ProfilePic ? (
                              <div className="profileImageBorder">
                                <img src={item?.QrTributesData?.QrProfileData?.ProfilePic} alt="profile" />
                              </div> 
                            ) : ( 
                              <div className="profileImageBorder">
                                <div className="black"></div>
                              </div> 
                            )
                          } */}
                            {/* <div className="tributeNameWithDate">
                            <p className="Nameee">{item?.TributeName}</p>                            
                          </div> */}
                          </div>
                          <p>{item?.Description}</p>
                          <Swiper
                            className="mySwiper"
                            loop={true}
                            modules={[Autoplay]}
                            autoplay={{
                              disableOnInteraction: false,
                              delay: 2500,
                            }}
                            slidesPerView={4}
                            spaceBetween={10}
                            breakpoints={{
                              // when window width is >= 0px
                              0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                              },
                              // when window width is >= 1024px
                              1024: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                              },
                            }}
                          >
                            {item?.UserTributesImageData?.map((datas) => (
                              <SwiperSlide
                                key={datas._id}
                                className="slidessTributes"
                              >
                                <Image src={datas?.Image} alt="tribute" />
                                <p>
                                  {datas?.Description === "undefined"
                                    ? ""
                                    : datas?.Description}
                                </p>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          <p className="tributerName">- {item?.TributeName}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* 
                <div className="col-sm-8">
                  <div className="leterCircle">
                    <p>{getInitials(item?.TributeName)}</p>
                  </div>
                  <p>{item?.TributeName}</p>
                </div>
                <div className="col-sm-2 datee">
                  <p>{moment(item?.createdAt).format("MMMM D, YYYY")}</p>
                </div>
                {isAuthenticated == "true" && (
                  <div className="col-sm-2">
                    <EditOutlined onClick={() => handleShowEdit(item._id)} />
                    <DeleteOutlined onClick={() => handleModalOpen(item?._id)} />
                  </div>
                )}

                <div className="col-sm-12">
                  <p className='tributeDescription'>{item?.Description}</p>
                </div> */}
              {/* </div> */}
            </div>
            {data?.totalCount > 10 && (
              <Pagination
                TotalData={data?.totalCount}
                handlePageChange={handlePageChange}
                currentPage={page}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <EditTributeForm tributeImageUpload={tributeImageUpload} id={ids} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        className="uploaddVideoPhoto"
      >
        <Modal.Body>
          <DeleteModal />
        </Modal.Body>
      </Modal>

      <Modal
        show={showSetting}
        onHide={handleCloseSetting}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <TributeSettingForm
            handleTributeTrue={handleTributeTrue}
            tributeImageUpload={tributeImageUpload}
            handleClose={handleCloseSetting}
            handleTributeFalse={handleTributeFalse}
            handleTributeImageUpload={handleTributeImageUpload}
          />
        </Modal.Body>
      </Modal>
      <DeleteModal
        handleDelete={handleDelete}
        id={ids}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />

      <Modal show={show} onHide={handleClose} className="uploaddVideoPhoto">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <AddTributeForm
            handleClose={handleClose}
            isAuthenticated={isAuthenticated}
            imageFields={imageFields}
            setImageFields={setImageFields}
            tributeImageUpload={tributeImageUpload}
            handleShowImage={handleShowImage}
            AllowToUploadPicture={AllowToUploadPicture}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showImage}
        onHide={handleCloseImage}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <AddImageInTribute
            isAuthenticated={isAuthenticated}
            postData={postData}
            imageFields={imageFields}
            t={t}
            handleCloseImage={handleCloseImage}
            tributeImageUpload={tributeImageUpload}
            AllowToUploadPicture={AllowToUploadPicture}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Tribute;
