import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Headers from '../Components/Headerr/Headers'
import ProffileeHeader from '../Components/ProfileDetail/ProffileeHeader'
import ProfileDetaislForm from '../Components/ProfileDetail/ProfileDetaislForm'

function PersonalDetail() {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className='col-sm-3 sideebarr'>
                        <Sidebar />
                    </div>    

                    <div className='col-sm-9 sideebarr'>
                        <Headers name={'profile'}/>
                        <ProffileeHeader />
                        <ProfileDetaislForm />
                    </div>   
                </div>    
            </div>
        </Fragment>
    )
}

export default PersonalDetail