import { createAsyncThunk } from "@reduxjs/toolkit";
import { resetEmailStart, resetEmailSuccess, resetEmailFailure } from "../Reducers/resetEmailReducer";
import axios from "axios";

export const resetEmail = createAsyncThunk(
  "resetEmail",
    async ({ values }, thunkAPI) => {
        try {
          thunkAPI.dispatch(resetEmailStart());
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/ForgotPassword `,
            values
          );
          thunkAPI.dispatch(resetEmailSuccess(response.data));
          return response.data;
        } catch (error) {
          thunkAPI.dispatch(resetEmailFailure(error.response.data.message)); // Dispatch the failure action
          return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
        }
    }
);