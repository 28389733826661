import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  bioFailure,
  bioStart,
  bioSuccess,
  updateBioFailure,
  updateBioStart,
  updateBioSuccess,
} from "../Reducers/bioReducer";
import Cookies from "js-cookie";
import axios from "axios";
import { QrAuthentication } from "./auth";
export const getBio = createAsyncThunk("auth", async ({ id }, thunkAPI) => {
  try {
    thunkAPI.dispatch(bioStart());
    const token = Cookies.get("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}UserBioRoutes/GetUserBioByUser/${id}`
    );
    const responseData = await response;

    thunkAPI.dispatch(bioSuccess(responseData));
    thunkAPI.dispatch(QrAuthentication(id));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(bioFailure(error.response.data.message));

    throw error;
  }
});
export const updateBio = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateBioStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserBioRoutes/EditUserBio/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updateBioSuccess(responseData));
      await thunkAPI.dispatch(getBio({ id }));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updateBioFailure(error.response.data.message));

      throw error;
    }
  }
);
