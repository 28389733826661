import React, { Fragment, useEffect, useState } from "react";
import loggo from "../../Assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { IoQrCodeOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import axios from "axios";
import { Button, Spin } from "antd";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import CheckQrDetail from "./CheckQrDetail";
import CheckQrDeatail from "./CheckQrDetail";
import NoData from "./NoData";

function CheckEmail() {
  const qrDetail = useSelector((state) => state.user.qrData);
  const { isAuthenticated, isAuthenticatedLoading } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = useState(false);
  const [profileloading, setProfileLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState(Cookies.get("token"));
  const [profileData, setProfileData] = useState(null);
  const [qrData, setQrData] = useState(null);

  const handleRedirectToDashboard = () => {
    const dashboardUrl = `${process.env.REACT_APP_REDIRECT_URL}?qrCodeId=${id}`;
    window.open(dashboardUrl, "_blank"); // Open in a new tab
  };

  const handleRegister = () => {
    navigate(`/pages/?qrCodeId=${id}`);
  };

  const handleSignInDifferent = () => {
    navigate(`/?qrCodeId=${id}`);
  };

  const handleCreateAccount = () => {
    navigate(`/?qrCodeId=${id}`);
  };

  const getMyProfile = async () => {
    setProfileLoading(true);
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/GetMyProfile`,
        config
      );
      const responseData = await response?.data?.data;
      setProfileData(responseData);
      setProfileLoading(false);
      return responseData;
    } catch (error) {
      setProfileLoading(false);
      return error;
    }
  };
  const getQrByid = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/getQrById/${id}`
      );
      const responseData = await response?.data?.data;
      setQrData(responseData);
      setLoading(false);
      return responseData;
    } catch (error) {
      setLoading(false);

      return error;
    }
  };

  useEffect(() => {
    getMyProfile();
    getQrByid();
  }, []);
  useEffect(() => {
    if (
      !isAuthenticatedLoading &&
      qrData?.IsActive &&
      qrDetail?.UserDetail?.Name
    ) {
      navigate(`/pages/bio/${id}`);
    }
  }, [qrData, qrDetail]);
  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/pages/bio/${id}`);
    }
  }, [isAuthenticated]);
  return (
    <Fragment>
      <div className="container-fluid nodata asdasd fines">
        <div className="col-sm-12">
          <img src={loggo} alt="ásd" />
          <IoQrCodeOutline color="#fff" size={50} />
          {isAuthenticatedLoading || loading || profileloading ? (
            <Spin
              style={{ color: "#fff" }}
              indicator={<LoadingOutlined spin style={{ fontSize: 100 }} />}
            />
          ) : !qrData ? (
            <NoData />
          ) : (
            <CheckQrDeatail
              qrDetail={qrDetail}
              qrData={qrData}
              profileData={profileData}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default CheckEmail;
