import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import Cookies from "js-cookie";
import {
  getLastQrIdFailure,
  getLastQrIdStart,
  getLastQrIdSuccess,
  paymentIntentFailure,
  paymentIntentStart,
  paymentIntentSuccess,
  purchaseQrFailure,
  purchaseQrStart,
  purchaseQrSuccess,
} from "../Reducers/paymentReducer";
import { QrList } from "./qrList";

export const getPaymentIntent = createAsyncThunk(
  "payment1",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(paymentIntentStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/PaymentIntent`,
        config
      );

      thunkAPI.dispatch(paymentIntentSuccess(response.data.clientSecret));

      return response;
    } catch (error) {
      thunkAPI.dispatch(paymentIntentFailure(error.response.data.message));

      throw error;
    }
  }
);

export const purchaseQr = createAsyncThunk(
  "auth",
  async ({ formData }, thunkAPI) => {
    try {
      thunkAPI.dispatch(purchaseQrStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/UserCreateQr`,
        formData,
        config
      );
      thunkAPI.dispatch(purchaseQrSuccess(response.data));
      thunkAPI.dispatch(QrList());
      return response;
    } catch (error) {
      thunkAPI.dispatch(purchaseQrFailure(error.response.data.message));

      throw error;
    }
  }
);

export const getLastQrId = createAsyncThunk("auth", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getLastQrIdStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/GetLastQrId`
    );
    thunkAPI.dispatch(getLastQrIdSuccess(response.data));
    return response;
  } catch (error) {
    thunkAPI.dispatch(getLastQrIdFailure(error.response.data.message));

    throw error;
  }
});

