import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  data: {},
};

const bioSlice = createSlice({
  name: "bio",
  initialState,
  reducers: {
    bioStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    bioSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    bioFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateBioStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    updateBioSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    updateBioFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  bioStart,
  bioSuccess,
  bioFailure,
  updateBioStart,
  updateBioSuccess,
  updateBioFailure,
  clearState,
} = bioSlice.actions;

export default bioSlice.reducer;
