import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Image, Input, Upload, message } from 'antd'
import { Modal } from 'react-bootstrap';
import ChangePassword from './ChangePassword';
import { useDispatch, useSelector } from "react-redux";
import editImage from "../../Assets/edit.png";
import { editMyProfile } from "../../Redux/Actions/editMyProfile";
import { clearState } from "../../Redux/Reducers/editMyProfileReducer";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const ProfileDetaislForm = () => {
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.editMyProfile);
  const { data } = useSelector((state) => state.getMyProfile);
  const [fileList, setFileList] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [form] = Form.useForm();

  const [edit, setEdit] = useState(true);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (value) => {
    const values = new FormData();
    values.append("Name", value.Name);
    values.append("Email", value.Email);
    values.append("PhoneNo", value.PhoneNo);
    values.append("Address", value.Address);
    values.append("ShippingAddress", value.ShippingAddress);
    const profile =
      value?.UserImage.fileList && value?.UserImage?.fileList[0]?.originFileObj;
    if (profile) {
      values.append("UserImage", profile);
    }
    await dispatch(editMyProfile({ values }));
    setEdit(!edit);
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        Name: data.Name,
        Email: data.Email,
        PhoneNo: data.PhoneNo,
        Address: data.Address,
        ShippingAddress: data.ShippingAddress,
        UserImage: data.UserImage,
      });
    }
    if (data?.UserImage) {
      setFileList([
        {
          uid: "-1",
          name: "profile.png",
          status: "done",
          url: data.UserImage,
        },
      ]);
    }
  }, [data, form]);
  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      dispatch(clearState());
    }
  }, [success, error, dispatch]);

  const {t} = useTranslation();
  return (
    <Fragment>
      <div className="container-fluid profilepAge">
        <div className="row">
          <div className="col-sm-12 tributeDetais">
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-sm-12 editBNtn">
                  <Button type="primary" onClick={() => setEdit(!edit)}>
                    <img src={editImage} alt="editImage" />
                  </Button>
                </div>
                {edit ? (
                  <div className="col-sm-12">
                    <Image
                      src={data?.UserImage}
                      alt="profile"
                      className="profileImage"
                    />
                  </div>
                ) : (
                  <div className="col-sm-12">
                    <Form.Item name="UserImage">
                      <Upload
                        action="/upload.do"
                        beforeUpload={() => false}
                        name="logo"
                        maxCount={1}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                      >
                        {fileList.length >= 1 ? null : (
                          <Button icon={<UploadOutlined />}>
                            {t("clickToUpload")}
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                  </div>
                )}
                <div className="col-sm-6">
                  <Form.Item label={t("name")} name="Name">
                    <Input
                      disabled={edit ? true : false}
                      placeholder={t("name")}
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  <Form.Item label={t("emailAddress")} name="Email">
                    <Input
                      disabled={edit ? true : false}
                      placeholder={t("emailAddress")}
                    />
                  </Form.Item>
                </div>

                {/* <div className="col-sm-6">
                  <Form.Item label="Phone No." name="PhoneNo"
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: 'Please enter a valid phone number with no alphabets',
                    }
                  ]}>
                    <Input
                      disabled={edit ? true : false}
                      placeholder="Phone No..."

                    />
                  </Form.Item>
                </div> */}

                {/* <div className="col-sm-6">
                  <Form.Item label="Address" name="Address">
                    <Input
                      disabled={edit ? true : false}
                      placeholder="Address..."
                    />
                  </Form.Item>
                </div> */}

                {/* <div className="col-sm-6">
                  <Form.Item label="Shipping Address" name="ShippingAddress">
                    <Input
                      disabled={edit ? true : false}
                      placeholder="Shipping Address..."
                    />
                  </Form.Item>
                </div> */}
                {!edit && (
                  <div className="col-sm-12 submitt updateBtn">
                    <Button type="primary" htmlType="submit">
                      {t("update")}
                    </Button>
                  </div>
                )}

                <div className="col-sm-12 changePassword updateBtn">
                  <Button type="priamry" onClick={handleShow}>
                    {t("changePassword")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="uploaddVideoPhoto">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ChangePassword handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ProfileDetaislForm;