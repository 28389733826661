import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import {
  deleteTributeImageFailure,
  deleteTributeImageStart,
  deleteTributeImageSuccess,
  deletetributeFailure,
  deletetributeStart,
  deletetributeSuccess,
  postTributeFailure,
  postTributeImageFailure,
  postTributeImageStart,
  postTributeImageSuccess,
  postTributeStart,
  postTributeSuccess,
  singleTributeFailure,
  singleTributeStart,
  singleTributeSuccess,
  tributeFailure,
  tributeStart,
  tributeSuccess,
  updatetributeFailure,
  updatetributeStart,
  updatetributeSuccess,
} from "../Reducers/tributeReducer";
export const getTribute = createAsyncThunk(
  "auth",
  async ({ id, page, pageSize }, thunkAPI) => {
    try {
      thunkAPI.dispatch(tributeStart());
      const token = Cookies.get("token");
     const config = {
       headers: {
         Authorization: token,
       },
     };
     const queryParams = {
       ...(pageSize && { size: pageSize }),

       ...(page && { page }),
     };
     const response = await axios.get(
       `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/GetTributeNameByUser/${id}`,
       {
         params: queryParams,
         headers: config.headers,
       }
     );
      const responseData = await response;

      thunkAPI.dispatch(tributeSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(tributeFailure(error.response.data.message));

      throw error;
    }
  }
);
export const postTribute = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postTributeStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/CreateTributeByUser/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(postTributeSuccess(responseData));
      await thunkAPI.dispatch(getTribute({ id }));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postTributeFailure(error.response.data.message));

      throw error;
    }
  }
);
export const postTributeImage = createAsyncThunk(
  "auth",
  async ({ id, formData }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postTributeImageStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/CreateTributeImagesByUser/${id}`,
        formData,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(postTributeImageSuccess(responseData));
      await thunkAPI.dispatch(getSingleTribute(id));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postTributeImageFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updateTribute = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updatetributeStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/EditUserTributeName/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updatetributeSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updatetributeFailure(error.response.data.message));

      throw error;
    }
  }
);
export const deleteTribute = createAsyncThunk("auth", async (id, thunkAPI) => {
  try {
    thunkAPI.dispatch(deletetributeStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/SoftDeleteUserTributeName/${id}`,

      config
    );
    const responseData = await response;

    thunkAPI.dispatch(deletetributeSuccess(responseData));
    // await thunkAPI.dispatch(getTribute({}));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(deletetributeFailure(error.response.data.message));

    throw error;
  }
});

export const getSingleTribute = createAsyncThunk(
  "auth",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(singleTributeStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/GetSingleUserTributeName/${id}`,
        {
          headers: config.headers,
        }
      );
      const responseData = await response;

      thunkAPI.dispatch(singleTributeSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(singleTributeFailure(error.response.data.message));

      throw error;
    }
  }
);

export const deleteTributeImage = createAsyncThunk(
  "auth",
  async ({ imageId, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteTributeImageStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/SoftDeleteUserTributeImage/${imageId}`,

        config
      );
      const responseData = await response;

      thunkAPI.dispatch(deleteTributeImageSuccess(responseData));
      await thunkAPI.dispatch(getSingleTribute(id));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(deleteTributeImageFailure(error.response.data.message));

      throw error;
    }
  }
);
