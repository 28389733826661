import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Reducers/authReducer";
import qrListReducer from "./Reducers/qrListReducer";
import cityListReducer from "./Reducers/cityListReducer";
import editProfileReducer from "./Reducers/editProfileReducer";
import bioReducer from "./Reducers/bioReducer";
import mediaReducer from "./Reducers/mediaReducer";
import timeLineReducer from "./Reducers/timeLineReducer";
import tributeReducer from "./Reducers/tributeReducer";
import tributeSettingReducer from "./Reducers/tributeSettingReducer";
import getMyProfile from "./Reducers/getMyProfileReducer";
import editMyProfile from "./Reducers/editMyProfileReducer";
import resetEmail from "./Reducers/resetEmailReducer";
import resetCode from "./Reducers/resetCodeReducer";
import forgetPassword from "./Reducers/forgetPasswordReducer";
import postProfileReducer from "./Reducers/postProfileReducer";
import paymentReducer from "./Reducers/paymentReducer";
import myTribute  from "./Reducers/myTributeReducer";
import FaqReducer from "./Reducers/FaqReducer";

const rootReducer = combineReducers({
  user: authReducer,
  userqr: qrListReducer,
  city: cityListReducer,
  editProfile: editProfileReducer,
  bio: bioReducer,
  media: mediaReducer,
  timeline: timeLineReducer,
  tribute: tributeReducer,
  tributeSetting: tributeSettingReducer,
  getMyProfile: getMyProfile,
  editMyProfile: editMyProfile,
  resetEmail: resetEmail,
  resetCode: resetCode,
  forgetPassword: forgetPassword,
  payment: paymentReducer,
  postProfile: postProfileReducer,
  myTribute: myTribute,
  FaqReducer: FaqReducer,
});
export default rootReducer;
