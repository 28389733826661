import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
};

const editProfileSlice = createSlice({
  name: "editProfile",
  initialState,
  reducers: {
    editProfileStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    editProfileSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    editProfileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.loading = false;
    },
  },
});

export const {
  editProfileStart,
  editProfileSuccess,
  editProfileFailure,

  clearState,
} = editProfileSlice.actions;

export default editProfileSlice.reducer;
