import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../../Redux/Reducers/postProfileReducer";
import { UploadOutlined } from "@ant-design/icons";
import logo from "../../Assets/logo.png";
import { postEmail, postProfile } from "../../Redux/Actions/postProfile";
import { useNavigate } from "react-router-dom";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { Login } from "../../Redux/Actions/auth";
import { useLocation } from "react-router-dom";
import Loader from "../Loader";
import { Modal } from "react-bootstrap";
import OnboardingSteps from "../OnBoarding/OnboardingSteps";
import { useTranslation } from "react-i18next";

const BeforeProf = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qrCodeId, setQrCodeId] = useState(null);
  const location = useLocation();
  const [showOnBoard, setShowOnBoard] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState();
  const handleShowOnBoard = () => setShowOnBoard(true);
  const handleCloseOnBoard = () => setShowOnBoard(false);

  const { success, error, loading, signUpLoading } = useSelector(
    (state) => state.postProfile
  );
  const { data } = useSelector((state) => state.getMyProfile);
  const [fileList, setFileList] = useState([]);

  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  // const handleClose = () => setShow(false);

  const onFinishStep1 = async (values) => {
    const resultAction = await dispatch(postEmail(values));
    setEmail(values.Email);
    if (postEmail.fulfilled.match(resultAction)) {
      handleNext();
    } else {
      setCurrentStep(3);
    }
  };
  const onFinishStep2 = async (values) => {
    values.Email = email;
    const resultAction = await dispatch(Login(values));
    if (postEmail.fulfilled.match(resultAction)) {
      navigate("/pages");
      // LinkAQrCode();
    }
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const [form] = Form.useForm();
  const validatePassword = (_, value) => {
    if (value && value.length < 4) {
      return Promise.reject("Password must be at least 4 characters long.");
    }
    return Promise.resolve();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    const qrCodeIdFromUrl = queryParams.get("qrCodeId");
    setQrCodeId(qrCodeIdFromUrl);
    // if (userId) {
    //     dispatch(fetchQrCodes(userId));
    // }
    localStorage.setItem("qrId", qrCodeId);
  }, [queryParams]);
  const { state } = useLocation();

  const onFinish = async (value) => {
    const values = new FormData();
    values.append("Name", value.Name);
    values.append("Email", email);
    values.append("PhoneNo", value.PhoneNo);
    values.append("Address", value.Address);
    values.append("Password", value.Password);
    const profile =
      value?.UserImage &&
      value?.UserImage.fileList &&
      value?.UserImage?.fileList[0]?.originFileObj;
    if (profile) {
      values.append("UserImage", profile);
    }
    await dispatch(postProfile(values));
    // await LinkAQrCode();
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };
  const onFinishFailed1 = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const changeLanguage = async (value) => {
    i18n.changeLanguage(value);
    await localStorage.setItem("lang", value);
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      // handleShowOnBoard();
      navigate("/pages", {
        state: {
          isOpen: true,
        },
      });
      dispatch(clearState());
    }
  }, [success, error, dispatch, state]);

  return (
    <Fragment>
      <div className="container-fluid loginForm beforeprof">
        <div className="col-sm-12 cennn mainPageLogo">
          <img src={logo} alt="asd" />
        </div>
        <FormOne
          onFinishStep1={onFinishStep1}
          onFinishFailed1={onFinishFailed1}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          loading={loading}
        />

        {currentStep === 2 && <FormTwo onFinishStep2={onFinishStep2} />}
        {currentStep !== 3 && (
          <div className="row signUplangDiv">
            <div className="col-sm-6 cennn ">
              <p>
                {t("Forget Password?")}{" "}
                <span
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/reset")}
                >
                  {t("Reset")}
                </span>
              </p>
            </div>
            <div className="col-sm-6 lisst cennn language signUplang">
              <Form>
                <Form.Item>
                  <Select
                    placeholder={t("Select Language")}
                    onChange={changeLanguage}
                  >
                    <Select.Option value="en">English</Select.Option>
                    <Select.Option value="fr">Français</Select.Option>
                    <Select.Option value="es">Español</Select.Option>
                    <Select.Option value="nl">Deutsch</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-sm-12 whiteBorder noPaddingtop">
                <Form.Item
                  name="Name"
                  label={t("Name")}
                  rules={[
                    {
                      required: true,
                      message: t("Please Enter Name!..."),
                    },
                  ]}
                >
                  <Input placeholder={t("Name")} />
                </Form.Item>
              </div>

              <div className="col-sm-12 passreset">
                <Form.Item
                  name="Password"
                  label={t("New Password")}
                  rules={[
                    {
                      required: true,
                      message: t("Please enter new Password!..."),
                    },
                    {
                      validator: validatePassword,
                    },
                  ]}
                >
                  <Input.Password placeholder={t("New Password")} />
                </Form.Item>
              </div>

              <div className="col-sm-12 passreset">
                <Form.Item
                  name="ConfirmPassword"
                  label={t("Confirm Password")}
                  rules={[
                    {
                      required: true,
                      message: t("Please confirm your Password"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("Password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t("The two passwords do not match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={t("Confirm Password")} />
                </Form.Item>
              </div>

              {/* <div className="col-sm-12">
                <Form.Item 
                  label="Shipping Address" 
                  name="ShippingAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Shipping Address!...",
                    },
                  ]}
                >
                  <Input placeholder="Shipping Address" />
                </Form.Item>
              </div> */}

              <div className="col-sm-12 beforebtns">
                <Form.Item
                  name="UserImage"
                  rules={[
                    {
                      required: true,
                      message: t("Please Upload Image!"),
                    },
                  ]}
                >
                  <Upload
                    action="/upload.do"
                    beforeUpload={() => false}
                    name="logo"
                    maxCount={1}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleChange}
                  >
                    {fileList.length >= 1 ? null : (
                      <Button
                        icon={<UploadOutlined />}
                        className="uploadProfileImage"
                      >
                        {t("Click to Upload Profile")}
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={signUpLoading}
                >
                  {signUpLoading ? <Loader /> : t("Save")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
      <Modal
        show={showOnBoard}
        onHide={handleCloseOnBoard}
        className="detailTimelineModal onboardiingModal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <OnboardingSteps handleClose={handleCloseOnBoard} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default BeforeProf;
