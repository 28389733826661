import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  cityData: [],
  deathCityData: [],
};

const cityListSlice = createSlice({
  name: "cityList",
  initialState,
  reducers: {
    cityListStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    cityListSuccess(state, action) {
      state.loading = false;
      state.cityData = action.payload.data.result;
    },
    cityListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deathCityListStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    deathCityListSuccess(state, action) {
      state.loading = false;
      state.deathCityData = action.payload.data.result;
    },
    deathCityListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  cityListStart,
  cityListSuccess,
  cityListFailure,
  deathCityListStart,
  deathCityListSuccess,
  deathCityListFailure,

  clearError,
} = cityListSlice.actions;

export default cityListSlice.reducer;
