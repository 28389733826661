import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Headers from '../Components/Headerr/Headers'
import Navigation from '../Components/QRDetail/Navigation'
import Timeline from '../Components/QRDetail/TimeLines/Timeline'
import FooterNav from '../Components/QRDetail/Footeer/FooterNav'
import TimelineHeader from '../Components/QRDetail/TimeLines/TimeLineHeader'

function TimeLinePage() {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className='col-sm-3 sideebarr'>
                        <Sidebar />
                    </div>    

                    <div className='col-sm-9 sideebarr'>
                        {/* <Headers /> */}
                        <TimelineHeader />
                        <Navigation />
                        <Timeline />
                        <FooterNav />
                    </div>   
                </div>    
            </div>
        </Fragment>
    )
}

export default TimeLinePage