import React from "react";

const NoData = () => {
  return (
    <p className="noDataDescription" style={{ textAlign: "center" }}>
      OOPS! <br />
      URL Incorrect or QR With This ID Does Not Exist
    </p>
  );
};

export default NoData;
