import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMyProfileFailure,
  getMyProfileStart,
  getMyProfileSuccess,
} from "../Reducers/getMyProfileReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const getMyProfile = createAsyncThunk("auth", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getMyProfileStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/GetMyProfile`,
      config
    );
    const responseData = await response;
    thunkAPI.dispatch(getMyProfileSuccess(responseData));
    // await thunkAPI.dispatch(getMyDetails());
    return responseData;
  } catch (error) {
    thunkAPI.dispatch(getMyProfileFailure(error.response.data.message));
    throw error;
  }
});