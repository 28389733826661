import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
import {
  deletetimeLineFailure,
  deletetimeLineStart,
  deletetimeLineSuccess,
  posttimeLineFailure,
  posttimeLineStart,
  posttimeLineSuccess,
  timeLineFailure,
  timeLineStart,
  timeLineSuccess,
  updatetimeLineFailure,
  updatetimeLineStart,
  updatetimeLineSuccess,
} from "../Reducers/timeLineReducer";
export const getTimeLine = createAsyncThunk(
  "auth",
  async ({ id, page, pageSize }, thunkAPI) => {
    try {
      thunkAPI.dispatch(timeLineStart());
      const token = Cookies.get("token");
      const queryParams = {
        ...(pageSize && { size: pageSize }),

        ...(page && { page }),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserTimeLineRoutes/GetTimeLineByUser/${id}`,
        {
          params: queryParams,
        }
      );
      const responseData = await response;

      thunkAPI.dispatch(timeLineSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(timeLineFailure(error.response.data.message));

      throw error;
    }
  }
);
export const postTimeLine = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(posttimeLineStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}UserTimeLineRoutes/CreateUserTimeLine/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(posttimeLineSuccess(responseData));
      await thunkAPI.dispatch(getTimeLine({ id }));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(posttimeLineFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updateTimeLine = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updatetimeLineStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserTimeLineRoutes/EditUserTimeLine/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updatetimeLineSuccess(responseData));
      await thunkAPI.dispatch(getTimeLine({}));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updatetimeLineFailure(error.response.data.message));

      throw error;
    }
  }
);
export const deleteTimeline = createAsyncThunk("auth", async (id, thunkAPI) => {
  try {
    thunkAPI.dispatch(deletetimeLineStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}UserTimeLineRoutes/SoftDeleteUserTimeLine/${id}`,

      config
    );
    const responseData = await response;

    thunkAPI.dispatch(deletetimeLineSuccess(responseData));
    await thunkAPI.dispatch(getTimeLine({}));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(deletetimeLineFailure(error.response.data.message));

    throw error;
  }
});
