import React, { Fragment } from "react";

import BeforeProf from "../Components/Logins/BeforeProf";
function EnterData() {
 
  return (
    <Fragment>
      <div className="container-fluid login">
        <div className="row login">
          <BeforeProf/>
        </div>
      </div>
    </Fragment>
  );
}

export default EnterData;
