import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  myTributeFailure,
  myTributeStart,
  myTributeSuccess,
} from "../Reducers/myTributeReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const myTribute = createAsyncThunk("auth", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(myTributeStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/getMyTributes`,
      config
    );
    const responseData = await response;

    thunkAPI.dispatch(myTributeSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(myTributeFailure(error.response.data.message));

    throw error;
  }
});
