import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myTribute } from "../../Redux/Actions/myTribute";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import edit from '../../Assets/edit.png';
import { Button, Image } from "antd";
import { Autoplay } from "swiper/modules";
import { Modal } from "react-bootstrap";
import EditTributeForm from "../QRDetail/Tributees/EditTributeForm";

const MyTributesListing = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [ids, setId] = useState();
  const [tributeImageUpload, setTributeImageUpload] = useState(true);

  const handleShowEdit = (id) => {
    setId(id);
    setShowEdit(true);
  };

  const handleTributeImageUpload = () => {
    setTributeImageUpload(!tributeImageUpload);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.myTribute);

  useEffect(() => {
    dispatch(myTribute());
  }, [dispatch, showEdit]);

  return (
    <Fragment>
      {data && data?.items?.length > 0 ? (
        <div className="container-fluid qrr myTributess">
        <div className="row noMArr fform tribute">
          <div className="col-sm-12 widthSeventy">
            {data?.items?.map((item) => (
              <div className="row inTribute" key={item._id}>
                <div className="col-sm-12 myTributeRow">
                  <div className="profiletribute">
                    <Button className="editBTn" onClick={() => handleShowEdit(item._id)}>
                      <img src={edit} alt="edit" />
                    </Button>
                    {
                      item?.QrTributesData?.QrProfileData?.ProfilePic ? (
                        <div className="profileImageBorder">
                          <Image src={item?.QrTributesData?.QrProfileData?.ProfilePic} alt="profile" />
                        </div> 
                      ) : ( 
                        <div className="profileImageBorder">
                          <div className="black"></div>
                        </div> 
                      )
                    }
                    {item?.QrTributesData?.QrProfileData?.Name ? (
                      <div className="tributeNameWithDate">
                        <p className="Nameee">{item?.QrTributesData?.QrProfileData?.Name}</p>
                        <p>{moment(item?.QrTributesData?.QrProfileData?.DOB).format("MMM Do YYYY")} -{" "}{moment(item?.QrTributesData?.QrProfileData?.DOD).format("MMM Do YYYY")}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    
                  </div>
                  <p>{item?.Description}</p>
                  <Swiper
                    className="mySwiper"
                    loop={true}
                    slidesPerView={4}
                    spaceBetween={10}
                    modules={[Autoplay]}
                    autoplay={{
                      disableOnInteraction: false,
                      delay: 2500
                    }}
                    breakpoints={{
                      // when window width is >= 200px
                      200: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      // when window width is >= 480px
                      575: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      // when window width is >= 640px
                      770: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      // when window width is >= 1024px
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    {item?.UserTributesImageData?.map((datas) => (
                      <SwiperSlide key={datas._id}>
                        <div className="row">
                          <div className="col-sm-12 slidessTributes">
                            <Image src={datas?.Image} alt="tribute" />
                            <p>{datas?.Description === "undefined" ? "" : datas?.Description}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <p className="tributerName">- {item?.TributeName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      ) : ( <></>)}
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EditTributeForm tributeImageUpload={tributeImageUpload} id={ids} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default MyTributesListing;