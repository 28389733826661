import { Button, Form, message } from 'antd';
import TextArea from 'antd/es/input/TextArea'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBio, updateBio } from '../../Redux/Actions/bio';
import { clearState } from '../../Redux/Reducers/bioReducer';
import QuillEditor from './QuillEditor';
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const BioModal = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, success, error, loading } = useSelector((state) => state.bio);
  const [quillData, setQuillData] = useState(""); // State to hold Quill editor data
  const [form] = Form.useForm();

  const handleQuillChange = (html) => {
    setQuillData(html);
    console.log(html, quillData, "<=====data");
  };
  const { id } = useParams();
  const onFinish = (values) => {
    dispatch(updateBio({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatch(getBio());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        Bio: data.Bio,
        Description: data.Description,
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      handleClose();

      dispatch(clearState());
    }
  }, [error, success]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          layout="vertical"
          name="person"
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
        >
          <div className="row mrginNo noMArr fform bioMOdaaal">
            <div className="col-sm-12">
              <Form.Item
                name="Bio"
                rules={[
                  {
                    required: true,
                    message: t("please_enter_bio"),
                  },
                ]}
              >
                <TextArea placeholder={t("please_enter_bio")} rows={7} />
              </Form.Item>
              <Form.Item
                name="Description"
                rules={[
                  {
                    required: true,
                    message: t("please_enter_description"),
                  },
                ]}
              >
                <QuillEditor
                  data={data.Description}
                  onChange={handleQuillChange}
                />
              </Form.Item>
            </div>

            <div className="col-sm-12 submitt">
              <Button htmlType="submit" type="primary" disabled={loading}>
                {loading ? <Loader /> : t("save")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default BioModal
