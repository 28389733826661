import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Headers from "../Components/Headerr/Headers";
// import QRHeader from '../Components/QRManageement/QRHeader'
import Listing from "../Components/QRManageement/Listing";
import Cookies from "js-cookie";
import axios from "axios";
import { QrList } from "../Redux/Actions/qrList";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { QrAuthentication } from "../Redux/Actions/auth";
function QRManagement() {
  const { id } = useParams();
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [qrCodeId, setQrCodeId] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const LinkAQrCode = async (qrId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}UserQrRoutes/LinkAQr`;

      const response = await axios.post(
        apiUrl,
        { qrId },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );


      navigate(location.pathname, { replace: true });
      dispatch(QrList());

      //  if(location.pathname){

      //  }

      localStorage.removeItem("qrId");
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const qrCodeIdFromUrl = queryParams.get("qrCodeId");
    setQrCodeId(qrCodeIdFromUrl);
  }, []);
  useEffect(() => {
    const qrId = qrCodeId ? qrCodeId : localStorage.getItem("qrId");
    if (qrId && qrId !== null) {
      LinkAQrCode(qrId);
    }
  }, [qrCodeId]);


  useEffect(() => {
    dispatch(QrAuthentication(id));
  }, [dispatch, id]);
  return (
    <Fragment>
      <div class="container-fluid main">
        <div class="row">
          <div className="col-sm-3 sideebarr">
            <Sidebar />
          </div>

          <div className="col-sm-9 sideebarr">
            <Headers name={"myProfiles"} />
            {/* <QRHeader /> */}
            <Listing />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default QRManagement;
