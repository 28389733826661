import React, { Fragment, useEffect } from "react";
import CheckEmail from "../Components/Authentication/CheckEmail.";
import { useParams } from "react-router-dom";
import { QrAuthentication, getQrDetail } from "../Redux/Actions/auth";
import { useDispatch } from "react-redux";

function Authentication() {
  const dispatch = useDispatch();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(QrAuthentication(id));
      dispatch(getQrDetail(id));
    }
  }, [dispatch, id]);
  return (
    <Fragment>
      <div className="container-fluid login">
        <div className="row login">
          <CheckEmail />
        </div>
      </div>
    </Fragment>
  );
}

export default Authentication;
