import React from "react";
import { Pagination as AntPagination } from "antd";
import { useTranslation } from "react-i18next";

const Pagination = ({ TotalData, handlePageChange, currentPage }) => {
  const { t } = useTranslation();
  return (
    <nav className="paginationMain pagination">
      <AntPagination
        total={TotalData}
        onChange={handlePageChange}
        defaultCurrent={currentPage}
        showTotal={(total) => `${t("total")} ${total} ${t("items")}`}
        size="large"
        showSizeChanger
        showQuickJumper
      />
    </nav>
  );
};

export default Pagination;
