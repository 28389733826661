import { Button, Form, Input, Modal, Select, Upload, message } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getMyDetails } from "../../Redux/Actions/auth";
import { cityList, deathCityList } from "../../Redux/Actions/cityList";
import DatePicker from "react-datepicker";
import { editProfile } from "../../Redux/Actions/editProfile";
import { clearState } from "../../Redux/Reducers/editProfileReducer";
import { useParams } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import CustomCalender from "./CustomCalender";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ProfileForm = ({ handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeathDate, setSelectedDeathDate] = useState(null);
  const profileEditorRef = useRef(null);
  const coverEditorRef = useRef(null);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [coverModalVisible, setCoverModalVisible] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [coverList, setCoverList] = useState([]);
  const [name, setName] = useState("");
  const [cityName, setCityName] = useState("");
  const [form] = Form.useForm();
  const { data } = useSelector((state) => state.user);
  // const { cityData, deathCityData } = useSelector((state) => state.city);
  const [size, setSize] = useState(100);
  const [citySize, setCitySize] = useState(100);
  const { success, error, loading } = useSelector((state) => state.editProfile);
  const { id } = useParams();
  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("DOB", selectedDate);
    formData.append("DOD", selectedDeathDate);
    formData.append("BornCity", values.BornCity);
    formData.append("DeathCity", values.DeathCity);
    if (profileEditorRef.current) {
      profileEditorRef.current.getImage().toBlob((blob) => {
        formData.append("ProfilePic", blob);
        dispatch(editProfile({ id, values: formData }));
      });
    }
    if (coverEditorRef.current) {
      coverEditorRef.current.getImage().toBlob((blob) => {
        formData.append("CoverImage", blob);
        dispatch(editProfile({ id, values: formData }));
      });
    }

    dispatch(editProfile({ id, values: formData }));
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateChange1 = (date) => {
    setSelectedDeathDate(date);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setSize((size) => size + 50);
    }
  };

  const handlePopupScroll1 = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setCitySize((size) => size + 50);
    }
  };

  const handleSearch = (value) => {
    setName(value);
  };

  const handleSearch1 = (value) => {
    setCityName(value);
  };
  const handleChange = ({ fileList }) => {
    const file = fileList[0]?.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfileImage(reader.result);
        setProfileModalVisible(true);
      };
    }
    setFileList(fileList);
  };

  const handleChange1 = ({ fileList }) => {
    const file = fileList[0]?.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setCoverImage(reader.result);
        setCoverModalVisible(true);
      };
    }
    setCoverList(fileList);
  };

  useEffect(() => {
    dispatch(getMyDetails({ id }));
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(cityList({ size, name }));
  // }, [dispatch, size, name]);

  useEffect(() => {
    dispatch(deathCityList({ citySize, cityName }));
  }, [dispatch, citySize, cityName]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        Name: data.Name,
        BornCity: data.BornCity,
        DeathCity: data.DeathCity,
        ProfilePic: data.ProfilePic,
        CoverImage: data.CoverImage,
      });
      if (data.ProfilePic) {
        setFileList([
          {
            uid: "-1",
            name: "profile.png",
            status: "done",
            url: data.ProfilePic,
          },
        ]);
      }
      if (data.CoverImage) {
        setCoverList([
          {
            uid: "-1",
            name: "cover.png",
            status: "done",
            url: data.CoverImage,
          },
        ]);
      }
      setSelectedDate(data.DOB ? moment(data.DOB).toDate() : null);
      setSelectedDeathDate(data.DOD ? moment(data.DOD).toDate() : null);
    }
  }, [data, form]);

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      dispatch(clearState());
      handleClose();
    }
  }, [error, success, dispatch]);

  return (
    <Fragment>
      <div className="container-fluid qrr">
        <div className="row noMArr fform bioMOdaaal">
          <div className="col-sm-12 topAPd">
            <Form
              layout="vertical"
              name="person"
              autoComplete="off"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <div className="row mrginNo">
                <div className="col-sm-4 nipus">
                  <Form.Item
                    name="Name"
                    label={t("name")}
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_name"),
                      },
                    ]}
                  >
                    <Input placeholder={t("name")} />
                  </Form.Item>
                </div>

                <div className="col-sm-4 nipus">
                  <Form.Item name={"DOB"} label={t("date_of_birth")}>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      placeholderText={t("date_of_birth")}
                      isClearable
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      renderCustomHeader={CustomCalender}
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-4 nipus">
                  <Form.Item
                    name="DOD"
                    label={t("date_of_death")}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (
                            !value ||
                            (selectedDate && value > selectedDate)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              t("Date of Death cannot be before Date of Birth")
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      selected={selectedDeathDate}
                      isClearable
                      onChange={handleDateChange1}
                      placeholderText={t("date_of_death")}
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      renderCustomHeader={CustomCalender}
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-4 nipus">
                  <Form.Item
                    name="BornCity"
                    label={t("birth_place")}
                    rules={[
                      {
                        required: true,
                        message: t("required_birth_place"),
                      },
                    ]}
                  >
                    <Input placeholder={t("birth_place")} />
                  </Form.Item>
                  {/* <Form.Item
                    name="BornCity"
                    label={t("birth_place")}
                    rules={[
                      {
                        required: true,
                        message: t("required_birth_place"),
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("select_city")}
                      showSearch
                      onSearch={handleSearch}
                      filterOption={false}
                      onPopupScroll={handlePopupScroll}
                    >
                      {cityData?.items?.map((e) => (
                        <Select.Option key={e._id} value={e._id}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </div>

                <div className="col-sm-4 nipus">
                  <Form.Item
                    name="DeathCity"
                    label={t("death_place")}
                    rules={[
                      {
                        required: true,
                        message: t("required_death_place"),
                      },
                    ]}
                  >
                    <Input placeholder={t("death_place")} />
                  </Form.Item>
                  {/* <Form.Item
                    name="DeathCity"
                    label={t("death_place")}
                    rules={[
                      {
                        required: true,
                        message: t("required_death_place"),
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("select_city")}
                      showSearch
                      onSearch={handleSearch1}
                      filterOption={false}
                      onPopupScroll={handlePopupScroll1}
                    >
                      {deathCityData?.items?.map((e) => (
                        <Select.Option key={e._id} value={e._id}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                </div>

                <div className="col-sm-6 fullWidth">
                  <Form.Item name="ProfilePic" label={t("profile_picture")}>
                    <Upload
                      beforeUpload={() => false}
                      name="logo"
                      maxCount={1}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                    >
                      {fileList.length >= 1 ? null : (
                        <Button icon={<UploadOutlined />}>
                          {t("clickToUpload")}
                        </Button>
                      )}
                    </Upload>
                    <Modal
                      className="imageUploadResizer"
                      visible={profileModalVisible}
                      onCancel={() => setProfileModalVisible(false)}
                      onOk={() => {
                        if (profileEditorRef.current) {
                          profileEditorRef.current.getImage().toBlob((blob) => {
                            const newFileList = [
                              {
                                uid: "-1",
                                name: "profile.png",
                                status: "done",
                                url: URL.createObjectURL(blob),
                              },
                            ];
                            setFileList(newFileList);
                            setProfileModalVisible(false);
                          });
                        }
                      }}
                      okText={t("save")}
                      cancelText={t("cancel")} // Add cancel text here
                    >
                      {profileImage && (
                        <AvatarEditor
                          ref={profileEditorRef}
                          image={profileImage}
                          width={250}
                          height={250}
                          border={10}
                          color={[255, 255, 255, 0.6]} // RGBA
                          scale={1.2}
                        />
                      )}
                    </Modal>
                  </Form.Item>
                </div>

                <div className="col-sm-6 fullWidth">
                  <Form.Item name="CoverImage" label={t("cover_picture")}>
                    <Upload
                      beforeUpload={() => false}
                      name="logo"
                      maxCount={1}
                      listType="picture-card"
                      fileList={coverList}
                      onChange={handleChange1}
                    >
                      {coverList.length >= 1 ? null : (
                        <Button icon={<UploadOutlined />}>
                          {t("clickToUpload")}
                        </Button>
                      )}
                    </Upload>
                    <Modal
                      className="imageUploadResizerCover mymodelss"
                      visible={coverModalVisible}
                      onCancel={() => setCoverModalVisible(false)}
                      onOk={() => {
                        if (coverEditorRef.current) {
                          coverEditorRef.current.getImage().toBlob((blob) => {
                            const newFileList = [
                              {
                                uid: "-1",
                                name: "cover.png",
                                status: "done",
                                url: URL.createObjectURL(blob),
                              },
                            ];
                            setCoverList(newFileList);
                            setCoverModalVisible(false);
                          });
                        }
                      }}
                      okText={t("save")}
                      cancelText={t("cancel")}
                    >
                      {coverImage && (
                        <AvatarEditor
                          ref={coverEditorRef}
                          image={coverImage}
                          width={500}
                          height={200}
                          border={10}
                          color={[255, 255, 255, 0.6]}
                          scale={1.2}
                        />
                      )}
                    </Modal>
                  </Form.Item>
                </div>

                <div className="col-sm-12 submitt">
                  <Button htmlType="submit" type="primary" disabled={loading}>
                    {loading ? <Loader /> : t("save")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileForm;
