import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { IoIosLink } from "react-icons/io";
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    EmailIcon,
} from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const ShareProfile = ({ handleClose }) => {
    const {t} = useTranslation();
    const { data } = useSelector((state) => state.user);
    const [qrId, setQrId] = useState(
      `${process.env.REACT_APP_REDIRECT_URL}${data?.QrId}`
    );
    const title = 'Check this out!';
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied(false);
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [copied]);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 shareProfiles">
                        <p>{t("shareProfile")}</p>
                    </div>
                    <div className="col-sm-12 shareProfile">
                        <FacebookShareButton url={qrId} quote={title}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton url={qrId} appId="your_app_id">
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>
                        <TwitterShareButton url={qrId} title={title}>
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <WhatsappShareButton url={qrId} title={title}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <LinkedinShareButton url={qrId} title={title}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <PinterestShareButton url={qrId} media={qrId}>
                            <PinterestIcon size={32} round />
                        </PinterestShareButton>
                        <EmailShareButton url={qrId} subject={title} body={qrId}>
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                        <CopyToClipboard text={qrId} onCopy={() => setCopied(true)}>
                            <Button className='copytoclipboard'>
                                <IoIosLink color='#fff' size={20}/>
                            </Button>
                        </CopyToClipboard>
                        {copied ? <span style={{ color: 'green', marginLeft: '10px' }}>{t("copied")}</span> : null}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default ShareProfile;
{/*
        VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    MailruShareButton,
    LivejournalShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    GabShareButton,
    VKIcon,
    OKIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    WeiboIcon,
    PocketIcon,
    InstapaperIcon,
    HatenaIcon,
    GabIcon,    
    
*/}


    
    {/* <VKShareButton url={qrId} title={title}>
        <VKIcon size={32} round />
    </VKShareButton>
    <OKShareButton url={qrId} title={title}>
        <OKIcon size={32} round />
    </OKShareButton>
    <RedditShareButton url={qrId} title={title}>
        <RedditIcon size={32} round />
    </RedditShareButton>
    <TumblrShareButton url={qrId} title={title}>
        <TumblrIcon size={32} round />
    </TumblrShareButton>
    <MailruShareButton url={qrId} title={title}>
        <MailruIcon size={32} round />
    </MailruShareButton>
    <LivejournalShareButton url={qrId} title={title}>
        <LivejournalIcon size={32} round />
    </LivejournalShareButton>
    <ViberShareButton url={qrId} title={title}>
        <ViberIcon size={32} round />
    </ViberShareButton>
    <WorkplaceShareButton url={qrId} title={title}>
        <WorkplaceIcon size={32} round />
    </WorkplaceShareButton>
    <LineShareButton url={qrId} title={title}>
        <LineIcon size={32} round />
    </LineShareButton>
    <WeiboShareButton url={qrId} title={title}>
        <WeiboIcon size={32} round />
    </WeiboShareButton>
    <PocketShareButton url={qrId} title={title}>
        <PocketIcon size={32} round />
    </PocketShareButton>
    <InstapaperShareButton url={qrId} title={title}>
        <InstapaperIcon size={32} round />
    </InstapaperShareButton>
    <HatenaShareButton url={qrId} title={title}>
        <HatenaIcon size={32} round />
    </HatenaShareButton>
    <GabShareButton url={qrId} title={title}>
        <GabIcon size={32} round />
    </GabShareButton> */}