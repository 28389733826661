import React, { Fragment, useEffect, useRef, useState } from "react";
import qrImage from "../../../Assets/qrcode.png";
import { Button, Select } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const QRPreview = ({ handleClose }) => {
  const { data } = useSelector((state) => state.user);
  const [format, setFormat] = useState("jpeg");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const imageRef = useRef();
  const downloadImage = async () => {
    if (format == "svg") {
      const svg = imageRef.current;
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const svgSize = svg.getBoundingClientRect();
      canvas.width = svgSize.width;
      canvas.height = svgSize.height;
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        let dataURL;
        if (format === "svg") {
          dataURL = "data:image/svg+xml;base64," + btoa(svgData);
        } else if (format === "png") {
          dataURL = canvas.toDataURL("image/png");
        } else if (format === "jpg") {
          dataURL = canvas.toDataURL("image/jpeg");
        }
        const link = document.createElement("a");
        link.download = `image.${format}`;
        link.href = dataURL;
        link.click();
      };

      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    } else {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}UserQrRoutes/DownloadImage/${data?.QrId}?format=${format}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: `image/${format}` });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `download.${format}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const HandlePrintV2 = async (format) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}UserQrRoutes/DownloadImage/${data?.QrId}?format=${format}`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: `image/${format}` });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `download.${format}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const svgElement = document.querySelector(".ssleevvee");
    if (svgElement) {
      imageRef.current = svgElement;
    }
  }, []);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12" style={{ display: "none" }}>
            <svg
              ref={imageRef}
              className="ssleevvee"
              style={{ strokeWidth: "3" }}
              dangerouslySetInnerHTML={{
                __html: `${data?.QrProfileData?.SvgPath?.replace(
                  /^<div>|<\/div>$/g,
                  ""
                )}`,
              }}
            />
          </div>
          {data && data?.QrProfileData?.QrMedia ? (
            <div className="col-sm-12 qrprevieews">
              <img src={data?.QrProfileData?.QrMedia} alt="QR Code" />
            </div>
          ) : (
            <div className="col-sm-12 qrprevieews">
              <img src={qrImage} alt="QR Code" />
            </div>
          )}
          <div className="col-sm-12 qrDownloadSelect">
            <Select onChange={(e) => setFormat(e)} value={format}>
              <Select.Option value="jpeg">JPEG</Select.Option>
              <Select.Option value="png">PNG</Select.Option>
              <Select.Option value="svg">SVG</Select.Option>
            </Select>
          </div>

          <div className="col-sm-12 qrprevieews">
            <Button
              className="swipereBtn"
              onClick={downloadImage}
              disabled={loading}
            >
              {loading ? <Loader /> : t("download")}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QRPreview;
