import { Button, Form, Input, message, Radio, Upload } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../../../Redux/Reducers/mediaReducer";
import { updateMedia } from "../../../Redux/Actions/media";
import CustomCalender from "../CustomCalender";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const EditVideoModal = ({ editData, handleCloseEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mediaType, setMediaType] = useState(
    editData.Type == "video" ? "video" : ""
  );
  
  const [selectedDate, setSelectedDate] = useState(null);
  const [fileList, setFileList] = useState([]);
  const { updateError, updateSuccess, loading } = useSelector(
    (state) => state.media
  );
  const [form] = Form.useForm();
  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };
  const onFinish = (value) => {
    const values = new FormData();
    if (value.Description) {
      values.append("Description", value.Description);
    }
    if (value.VideoUrl) {
      values.append("VideoUrl", value.VideoUrl);
    }
    if (selectedDate) {
      values.append("Date", selectedDate);
    }

    const file =
      value?.file &&
      value?.file.fileList &&
      value?.file?.fileList[0]?.originFileObj;
    if (file) {
      values.append("image", file);
    }
    if (file) {
      values.append("Media", file);
    }
    const id = editData._id;
    dispatch(updateMedia({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleMediaChange = (e) => {
    setMediaType(e.target.value);
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        Description: editData.Description,
        VideoUrl: editData.VideoUrl,
        file: editData.Media,
      });
      const formattedDate = editData.Date ? moment(editData.Date).utc() : null;

      setSelectedDate(formattedDate);
    }
    if (editData.Media) {
      setFileList([
        {
          uid: "-1",
          name: "profile.png",
          status: "done",
          url: editData.Media,
        },
      ]);
    }
  }, [editData, form]);
  useEffect(() => {
    if (updateError) {
      message.error(updateError);
      dispatch(clearState());
    }
    if (updateSuccess) {
      message.success(updateSuccess);
      dispatch(clearState());
      handleCloseEdit();
    }
  }, [updateSuccess, updateError]);
  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          form={form}
          initialValues={editData}
          autoComplete="off"
        >
          <div className="row uploadImageForm">
            <div className="col-sm-12 uploadHeading">
              <p>{t("editImageandVideo")}</p>
            </div>
            <div className="col-sm-12">
              <Form.Item>
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  onChange={handleMediaChange}
                  value={mediaType}
                >
                  <Radio.Button value="">{t("image")}</Radio.Button>
                  <Radio.Button value="video">{t("video")}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="Description"
                label={t("description")}
                rules={[
                  {
                    required: true,
                    message: t("please_enter_description"),
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder={t("description")} />
              </Form.Item>
            </div>

            <div className="col-sm-12 datee">
              <Form.Item name="EventDate" label={t("eventDate")}>
                <DatePicker
                  placeholderText={t("eventDate")}
                  selected={selectedDate ? new Date(selectedDate) : null}
                  onChange={(date) => setSelectedDate(date)}
                  isClearable
                  dateFormat="MMMM d, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  renderCustomHeader={CustomCalender}
                />
              </Form.Item>
            </div>

            {mediaType === "" && (
              <div className="col-sm-12">
                <Form.Item
                  name="file"
                  label={t("uploadImage")}
                  rules={[
                    {
                      required: true,
                      message: `${t("pleaseUploadImage")}`
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={() => false}
                    accept="image/*"
                    name="logo"
                    maxCount={1}
                    fileList={fileList}
                    onChange={handleChange}
                    listType="picture-card"
                  >
                    <Button icon={<UploadOutlined />}>{t("clickToUpload")}</Button>
                  </Upload>
                </Form.Item>
              </div>
            )}

            {mediaType === "video" && (
              <div className="col-sm-12 videoLink">
                <Form.Item
                  name="VideoUrl"
                  label={t("youtubeURL")}
                  rules={[
                    {
                      required: true,
                      message: `${t("addVideoLink")}`,
                    },
                  ]}
                >
                  <Input placeholder={t("youtubeURL")} />
                </Form.Item>
              </div>
            )}

            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : t("save")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default EditVideoModal;
