import { Button } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBio } from "../../Redux/Actions/bio";
import { Modal } from "react-bootstrap";
import BioModal from "./BioModal";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Bio = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { isAuthenticated } = useSelector((state) => state.user);
  const isAuthenticated = Cookies.get("isAuthenticated");
  const { data } = useSelector((state) => state.bio);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { id } = useParams();
  useEffect(() => {
    dispatch(getBio({ id }));
  }, [dispatch, id]);

  return (
    <Fragment>
      <div className="container-fluid qrr">
        <div className="row noMArr fform">
          {isAuthenticated == "true" && (
            <div className="col-sm-12 widthSeventy submitt">
              <Button onClick={handleShow}>{t("manageBio")}</Button>
            </div>
          )}
          <div className="col-sm-12 topAPd widthSeventy">
            <p className="UserBioMessage">{data?.Bio}</p>
            <div className="httml" dangerouslySetInnerHTML={{ __html: data?.Description }} />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="EditProfile">
        <Modal.Header closeButton></Modal.Header>

        <BioModal handleClose={handleClose} />
      </Modal>
    </Fragment>
  );
};

export default Bio;
