import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  data: [],
};

const myTributeSlice = createSlice({
  name: "myTribute",
  initialState,
  reducers: {
    myTributeStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    myTributeSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.result;
      state.success = action.payload.data.message;
    },
    myTributeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearMyTributeState: (state) => {
      state.error = null;
      state.success = null;
      state.data = null;
    },
  },
});

export const {
  myTributeStart,
  myTributeSuccess,
  myTributeFailure,
  clearMyTributeState,
} = myTributeSlice.actions;

export default myTributeSlice.reducer;