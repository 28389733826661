import { Button } from 'antd'
import React, { useState } from 'react'
import { ReactComponent as QuestionMark } from '../../../Assets/QuestionMark.svg';
import { Modal } from 'react-bootstrap';
import OnboardingSteps from '../../OnBoarding/OnboardingSteps';
import { IoQrCodeOutline, IoShareOutline } from 'react-icons/io5';
import QRPreview from './QRPreview';
import ShareProfile from './ShareProfile';
import Cookies from "js-cookie";
import { useSelector } from 'react-redux';

const HeaderComps = () => {
    const userToken = Cookies.get("token");
    const { isAuthenticated } = useSelector((state) => state.user);

    const [showOnBoard, setShowOnBoard] = useState(false);
    const [showQR, setShowQR] = useState(false);
    const [showShare, setShowShare] = useState(false);

    const handleShowOnBoard = () => setShowOnBoard(true);
    const handleCloseOnBoard = () => setShowOnBoard(false);

    const handleShowQR = () => setShowQR(true);
    const handleCloseQR = () => setShowQR(false);

    const handleShowShare = () => setShowShare(true);
    const handleCloseShare = () => setShowShare(false);
    return (
      <>
        <div className="headerDetaill">
          <Button className="onBoardd" onClick={handleShowOnBoard}>
            <QuestionMark />
          </Button>
          {isAuthenticated && (
            <Button className="onBoarddSvg" onClick={handleShowQR}>
              <IoQrCodeOutline color="#87562E" size={30} />
            </Button>
          )}
          <Button className="onBoarddSvg" onClick={handleShowShare}>
            <IoShareOutline color="#87562E" size={30} />
          </Button>
        </div>

        <Modal
          show={showOnBoard}
          onHide={handleCloseOnBoard}
          className="detailTimelineModal onboardiingModal"
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <OnboardingSteps handleClose={handleCloseOnBoard} />
          </Modal.Body>
        </Modal>

        <Modal
          show={showQR}
          onHide={handleCloseQR}
          className="detailTimelineModal onboardiingModal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <QRPreview handleClose={handleCloseQR} />
          </Modal.Body>
        </Modal>

        <Modal
          show={showShare}
          onHide={handleCloseShare}
          className="detailTimelineModal onboardiingModal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ShareProfile handleClose={handleCloseShare} />
          </Modal.Body>
        </Modal>
      </>
    );
}

export default HeaderComps
