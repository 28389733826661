import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  isSuccess: false,
  signUpLoading: false,
  signUpToken: null,
};

const postProfileSlice = createSlice({
  name: "postProfile",
  initialState,
  reducers: {
    postProfileStart(state, payload) {
      state.signUpLoading = true;
      state.error = null;
    },

    postProfileSuccess(state, action) {
      state.signUpLoading = false;
      state.success = action.payload.data.message;
      state.signUpToken = action.payload.data.data;
    },
    postProfileFailure(state, action) {
      state.signUpLoading = false;
      state.error = action.payload;
    },

    postEmailStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    postEmailSuccess(state, action) {
      state.loading = false;
      state.isSuccess = false;
    },
    postEmailFailure(state, action) {
      state.loading = false;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.isSuccess = false;
      state.signUpLoading = false;
    },
  },
});

export const {
  postProfileStart,
  postProfileSuccess,
  postProfileFailure,
  postEmailStart,
  postEmailSuccess,
  postEmailFailure,

  clearState,
} = postProfileSlice.actions;

export default postProfileSlice.reducer;
