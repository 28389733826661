import { Button, DatePicker, Form, Input, Upload, message } from 'antd';
import React, { Fragment, useEffect, useState } from "react";
import { UploadOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTributeImage,
  getSingleTribute,
  postTributeImage,
  updateTribute,
} from "../../../Redux/Actions/tributes"; // Assuming you have an update action
import { DeleteOutlined } from "@ant-design/icons";
import { myTribute } from '../../../Redux/Actions/myTribute';
import Loader from "../../Loader";
import { useTranslation } from 'react-i18next';

const EditTributeForm = ({ tributeImageUpload, id }) => {
  const {t} = useTranslation();
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const dispatch = useDispatch();
  const [imageFields, setImageFields] = useState([{ id: 1 }]);
  const { singleData, loading } = useSelector((state) => state.tribute);

  const onFinish = (value) => {
    const { TributeName, Description } = value;
    const values = {
      TributeName,
      Description,
    };
    const id = singleData._id;
    const res = dispatch(updateTribute({ id, values }));
    dispatch(myTribute());
    if (updateTribute.fulfilled(res)) {
      message.success("Data Updated Successfully");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleAddField = async (index) => {
    // Validate the description field before proceeding
    try {
      const values = await form1.validateFields([`file${index}`]);
      const valuesDesc = await form1.validateFields([
        `imageDescription${index}`,
      ]);
      const description = values[`file${index}`];
      const imageDesc = valuesDesc[`imageDescription${index}`];
      if (!description) {
        message.warning("Please add a description.");
        return;
      }

      // Disable the button for the current index
      setDisabledButtons([...disabledButtons, index]);

      if (imageFields.length < 20) {
        const newId =
          imageFields.length > 0
            ? imageFields[imageFields.length - 1].id + 1
            : 1;
        setImageFields([...imageFields, { id: newId }]);
      } else {
        message.warning("You can add up to 20 images.");
        return;
      }

      const formData = new FormData();
      formData.append("Description", imageDesc);

      const file = form1.getFieldValue(`file${index}`);
      const files = file?.fileList[0]?.originFileObj;

      if (file) {
        formData.append("file", files);
      }
      const id = singleData._id;
      dispatch(postTributeImage({ id, formData }));
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };
  const handleRemoveField = (id) => {
    setImageFields(imageFields.filter((field) => field.id !== id));
  };
  const handleDeleteImage = (imageId) => {
    const id = singleData._id;
    dispatch(deleteTributeImage({ imageId, id }));
  };

  useEffect(() => {
    dispatch(getSingleTribute(id));
  }, [dispatch]);

  useEffect(() => {
    if (singleData) {
      form.setFieldsValue({
        TributeName: singleData.TributeName,
        Description: singleData.Description,
      });

      //   // Initialize image fields based on the existing data
      //   if (singleData.UserTributesImageData) {
      //     const initialImageFields = singleData.UserTributesImageData.map(
      //       (image, index) => ({
      //         id: index + 1,
      //       })
      //     );
      //     setImageFields(initialImageFields);

      //     const initialImages = {};
      //     singleData.UserTributesImageData.forEach((image, index) => {
      //       initialImages[`image${index + 1}`] = {
      //         fileList: [
      //           {
      //             uid: image._id,
      //             name: `image${index + 1}.jpg`,
      //             status: "done",
      //             url: image.Image,
      //           },
      //         ],
      //       };
      //       initialImages[`imageDescription${index + 1}`] = image.Description;
      //     });
      //     form.setFieldsValue({ images: initialImages });
      //   }
    }
  }, [singleData]);
  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          form={form}
          initialValues={singleData}
          autoComplete='off'
        >
          <div className="row uploadImageForm edittrribute">
            <div className="col-sm-12 uploadHeading">
              <p>{t("editTribute")}</p>
            </div>

            <div className="col-sm-12 uploadHeading">
              <p>{t("capture_your_thoughts_about_write_your_feelings_or_share_a_memory")}</p>
            </div>

            <div className="col-sm-12 videoLink">
              <Form.Item
                name="TributeName"
                label={t("name")}
                rules={[
                  {
                    required: true,
                    message: t("please_add_name"),
                  },
                ]}
              >
                <Input placeholder={t("name")} />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="Description"
                label={t("tribute")}
                rules={[
                  {
                    required: true,
                    message: t("please_add_tribute"),
                  },
                ]}
              >
                <Input.TextArea rows={7} placeholder={t("tribute")} />
              </Form.Item>
            </div>

            {singleData?.UserTributesImageData?.map((item, i) => (
              <div key={i} className="col-sm-12 EditTributeFormImage">
                <img src={item.Image} />
                <p>
                  {item?.Description === "undefined" ? "" : item?.Description}
                </p>
                <DeleteOutlined onClick={() => handleDeleteImage(item._id)} />
              </div>
            ))}

            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : t("save")}
              </Button>
            </div>
          </div>
        </Form>

        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          form={form1}
          initialValues={singleData}
          autoComplete='off'
        >
          <div className="uploadImageForm edittrribute row">
            <div className="col-sm-12 addPicture">
              {imageFields.map((field, index) => (
                <div className="row" key={field.id}>
                  <div className="col-sm-4">
                    <Form.Item
                      name={`file${index + 1}`}
                      label={`${t("image")} ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: t("please_upload_image"),
                        },
                      ]}
                    >
                      <Upload
                        action="/upload.do"
                        beforeUpload={() => false}
                        accept="image/*"
                        name="logo"
                        maxCount={1}
                        listType="picture-card"
                      >
                        <Button icon={<UploadOutlined />}>
                        {t("click_to_upload")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>

                  <div className="col-sm-4 imageDescription">
                    <Form.Item
                      name={`imageDescription${index + 1}`}
                      label={t("description")}
                    >
                      <Input placeholder={t("description")} />
                    </Form.Item>
                  </div>

                  <div className="col-sm-2 svgBtn">
                    <Form.Item label={t("add")}>
                      <Button
                        type="primary"
                        onClick={() => handleAddField(index + 1)}
                        disabled={
                          disabledButtons.includes(index + 1) ||
                          imageFields.length >= 20
                        }
                      >
                        {t("upload")} <PlusOutlined />
                      </Button>
                    </Form.Item>
                  </div>

                  <div className="col-sm-2 svgBtn">
                    {imageFields.length > 1 && (
                      <Form.Item label={t("remove")}>
                        <Button
                          type="primary"
                          onClick={() => handleRemoveField(field.id)}
                        >
                          {t("remove")} <MinusOutlined />
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default EditTributeForm;
