import React, { Fragment, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Button } from 'antd';
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from '../../Sidebar/Sidebar';
import HeaderComps from '../HeaderComponjent/HeaderComps';
import TributeLine from '../HeaderComponjent/TributeLine';
import { useTranslation } from "react-i18next";
import logoo from '../../../Assets/logoDashboard.png'

const PhotosHeader = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <div className="container-fluid qrHeaders">
        <div className="row headeerr mobile">
          <div className="col-sm-12">
            <div className="offcannvas">
              <Button onClick={handleShow} className="onBoarddSvg">
                <GiHamburgerMenu color="#87562E" size={30} />
              </Button>
              <p>{t("photos")}</p>
            </div>
            <HeaderComps />
          </div>
        </div>
      </div>
      <TributeLine />
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <div className="row">
            <div className="col-sm-12 logoImage imageOfLogo">
              <img src={logoo} alt="Logo" />
            </div>
          </div>
        </Offcanvas.Header>
        <Sidebar handleClose={handleClose} />
      </Offcanvas>
    </Fragment>
  );
};

export default PhotosHeader
