import React, { Fragment, useEffect } from 'react'
import logo from '../../Assets/logo.png'
import { Button, Form, Input, message } from 'antd'
import frwd from '../../Assets/rightArrow.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetEmail } from '../../Redux/Actions/resetEmail'
import { clearState } from '../../Redux/Reducers/resetEmailReducer'
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const ResetPassswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector((state) => state.resetEmail);

  const onFinish = async (values) => {
    await dispatch(resetEmail({ values }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      navigate("/reset-code");
      dispatch(clearState());
    }
  }, [error, success, dispatch]);
  return (
    <Fragment>
      <div className="container-fluid loginForm">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 cennn mainPageLogo">
              <img src={logo} alt="asd" />
            </div>

            <div className="col-sm-12 whiteBorder">
              <Form.Item
                name="Email"
                label={t("Email Address")}
                rules={[
                  {
                    required: true,
                    message: t("Please Enter Email!..."),
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!...",
                  },
                ]}
              >
                <Input placeholder={t("Email")} />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Button htmlType="submit" type="primary" disabled={loading}>
                {" "}
                {loading ? <Loader /> : t("Reset")} <img src={frwd} alt="asd" />
              </Button>
            </div>
            <div className="col-sm-12 cennn">
              <p>
                {t("Do you want to Login?")}
                <span
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/")}
                >
                  {t("Login")}
                </span>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default ResetPassswordForm
