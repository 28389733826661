import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  qrListFailure,
  qrListStart,
  qrListSuccess,
} from "../Reducers/qrListReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const QrList = createAsyncThunk("auth", async (values, thunkAPI) => {
  try {
    thunkAPI.dispatch(qrListStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}UserQrRoutes/MyQrs`,
      config
    );
    const responseData = await response;

    thunkAPI.dispatch(qrListSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(qrListFailure(error.response.data.message));

    throw error;
  }
});
