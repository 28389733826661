// Example i18n configuration (i18n.ts)
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./locales/en.json"; // Replace with your actual JSON file paths
import frTranslation from "./locales/fr.json";
import esTranslation from "./locales/es.json";
import nlTranslation from "./locales/nl.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
      es: { translation: esTranslation },
      nl: { translation: nlTranslation },
    },
    fallbackLng: "en", // Default language if translation key is not found
    detection: {
      order: ["localStorage", "navigator"],
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
