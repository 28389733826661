import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
};

const resetEmailSlice = createSlice({
    name: "resetEmail",
    initialState,
    reducers: {
        resetEmailStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.success = null;
        },

        resetEmailSuccess(state, action) {
            state.loading = false;
            state.isAuthenticated = true;
            state.success = action.payload.message;
        },
        logoutResetEmailSuccess(state, action) {
            state.loading = false;
            state.data = {};
            state.success = action.payload;
            state.token = null;
        },

        resetEmailFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.success = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearsuccess: (state) => {
            state.success = null;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.passwordSuccess = null;
            state.passwordError = null;
        },
    },
});

export const {
    resetEmailStart,
    resetEmailSuccess,
    logoutResetEmailSuccess,
    resetEmailFailure,
    clearError,
    clearsuccess,
    clearState,
} = resetEmailSlice.actions;

export default resetEmailSlice.reducer;