import { Button, Form, Input } from "antd";
import React from "react";
import frwd from "../../Assets/rightArrow.png";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const FormOne = ({ onFinishStep1, onFinishFailed1, currentStep, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinishStep1}
        onFinishFailed={onFinishFailed1}
        autoComplete="off"
      >
        <div className="col-sm-12 whiteBorder">
          <Form.Item
            label={t("Email Address")}
            name="Email"
            rules={[
              {
                required: true,
                message: t("Please Enter Email!..."),
              },
            ]}
          >
            <Input disabled={currentStep !== 1} placeholder={t("Email")} />
          </Form.Item>
        </div>

        {currentStep === 1 && (
          <div className="col-sm-12">
            {/* <Button type="primary" htmlType="submit">
              Next
            </Button> */}
            <Button htmlType="submit" type="primary" disabled={loading}>
              {loading ? <Loader /> : t("Next")} <img src={frwd} alt="asd" />
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default FormOne;
