import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editProfileFailure,
  editProfileStart,
  editProfileSuccess,
} from "../Reducers/editProfileReducer";
import Cookies from "js-cookie";
import axios from "axios";
import { getMyDetails } from "./auth";
export const editProfile = createAsyncThunk(
  "auth",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(editProfileStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserProfileRoutes/EditUserProfile/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(editProfileSuccess(responseData));
      await thunkAPI.dispatch(getMyDetails({ id }));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(editProfileFailure(error.response.data.message));

      throw error;
    }
  }
);
