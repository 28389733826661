import { Button } from "antd";
import React from "react";
import "./Payment.css";
import { MdOutlineComputer } from "react-icons/md";
import { FaAmazon } from "react-icons/fa";
import { SiEtsy } from "react-icons/si";
import { useTranslation } from "react-i18next";

const PurchaseModal = ({
  setModalOpen,
  handleClose,
  handleModalOpen,
  modalOpen,
  handleCloseDetail,
  handleFrameModalOpen,
}) => {
  const closeFirstModal = () => {
    handleCloseDetail();
    handleFrameModalOpen();
  };
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="row purchaseModal">
        <div className="col-sm-12 purchaseDesc">
          <p>{t("purchaseDetailsdescription")}</p>
        </div>
        <div className="col-sm-12 purchaseDetail brownBorder">
          <MdOutlineComputer color="#87562E" className="svgSize" />
          <h4>{t("purchaseDigitalPage")}</h4>
          <p>{t("digitalPageDetails")}</p>

          <p>$49.99</p>
          <Button onClick={closeFirstModal}>{t("purchaseDigitalPage")}</Button>
        </div>
        <div className="col-sm-12 purchaseDetail ">
          <FaAmazon color="#87562E" className="svgSize" />
          <h4>{t("purchaseMemorialPlaque")}</h4>
          <p>
            <p>{t("qrPlaqueDescription")}</p>
          </p>
          <Button className="brownBgNewLink">
            <a target="_blank" href="https://amazon.com/dp/B0DDT3HVTQ">
              {t("purchaseMemorialPlaque")}
            </a>
          </Button>
        </div>
        <div className="col-sm-12 purchaseDetail ">
          <SiEtsy color="#87562E" className="svgSize" />
          <h4>{t("orderOnEtsy")}</h4>
          <p>{t("qrPlaqueDescription")}</p>

          <Button className="brownBgNewLink">
            <a
              target="_blank"
              href="https://www.etsy.com/listing/1787318192/memorial-webpage-and-qr-code-memorial"
            >
              {t("purchaseMemorialPlague")}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseModal;
