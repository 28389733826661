import React, { Fragment, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Headers from "../Components/Headerr/Headers";
import FAQLIsting from "../Components/FAQ/FAQLIsting";

const FAQ = () => {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className="col-sm-3 sideebarr">
                        <Sidebar />
                    </div>

                    <div className="col-sm-9 sideebarr">
                        <Headers name={'faq'}/>
                        <FAQLIsting />
                    </div>
                </div>
            </div>
        </Fragment>
  );
}

export default FAQ;
