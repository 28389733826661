import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile } from "../../Redux/Actions/getMyProfile";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import { IoMdAdd } from "react-icons/io";
import { loadStripe } from "@stripe/stripe-js";
import { GiHamburgerMenu } from "react-icons/gi";
import { Modal } from "react-bootstrap";
import PurchaseModal from "../QRManageement/PurchaseModal";
import PaymentForm from "../QRManageement/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import SelectFrame from "../QRDetail/SelectFrame";
import OnboardingSteps from "../OnBoarding/OnboardingSteps";
import { useTranslation } from "react-i18next";
import logoo from "../../Assets/logoDashboard.png";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Headers = ({ name }) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getMyProfile);
  const [show, setShow] = useState(false);
  const [frame, setFrame] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalOpenDetail, setModalOpenDetail] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [frameModal, setFramModal] = useState(false);
  const handleFrameModalOpen = () => {
    setFramModal(true);
  };
  const handleFrameModalClose = () => {
    setFramModal(false);
  };
  const handleModalOpenDetail = () => {
    setModalOpenDetail(!modalOpenDetail);
  };
  const handleCloseOpenDetail = () => setModalOpenDetail(false);

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  const handleClosePayment = () => setModalOpen(false);

  const [showOnBoard, setShowOnBoard] = useState(false);

  const handleCloseOnBoard = () => setShowOnBoard(false);
  const handleShowOnBoard = () => setShowOnBoard(true);

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);
  const { t } = useTranslation();

  const handleNaviagte = () => {
    navigate("/profile");
  };
  const showAddQRButton = location.pathname === "/pages";
  const userToken = Cookies.get("token");

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row headeerr mobile">
          <div className="col-sm-12">
            {showAddQRButton && (
              <Button className="addQRBtn" onClick={handleModalOpenDetail}>
                <IoMdAdd color="#fff" size={25} />
              </Button>
            )}
            <div className="offcannvas">
              <Button onClick={handleShow} className="onBoarddSvg">
                <GiHamburgerMenu color="#87562E" size={30} />
              </Button>
              <p>{t(name)}</p>
            </div>
            {userToken && (
              <div
                className="headerDetaill"
                onClick={handleNaviagte}
                style={{ cursor: "pointer" }}
              >
                <img src={data?.UserImage} alt="profile" className="profile" />
                <p>{data?.Name}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <div className="row">
            <div className="col-sm-12 logoImage imageOfLogo">
              <img src={logoo} alt="Logo" style={{ width: "100%" }} />
            </div>
          </div>
        </Offcanvas.Header>
        <Sidebar handleClose={handleClose} />
      </Offcanvas>

      <Modal
        show={showOnBoard}
        onHide={handleCloseOnBoard}
        className="detailTimelineModal onboardiingModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <OnboardingSteps handleClose={handleCloseOnBoard} />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalOpenDetail}
        onHide={handleCloseOpenDetail}
        className="purchaseModal purchaseModalmainn"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h5> {t("purchaseDigitalPage")} </h5>
        </Modal.Header>

        <Modal.Body>
          <PurchaseModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            handleClose={handleClosePayment}
            handleCloseDetail={handleCloseOpenDetail}
            handleModalOpen={handleModalOpen}
            handleFrameModalOpen={handleFrameModalOpen}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={frameModal}
        onHide={handleFrameModalClose}
        className="purchaseModal"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h5>{t("selectQrFrame")}</h5>
        </Modal.Header>
        <Modal.Body>
          <SelectFrame
            setFrame={setFrame}
            handleModalOpen={handleModalOpen}
            handleFrameModalClose={handleFrameModalClose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalOpen}
        onHide={handleClosePayment}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Elements stripe={stripePromise}>
            <PaymentForm
              frame={frame}
              handleClose={handleClosePayment}
              handleShowOnBoard={handleShowOnBoard}
            />
          </Elements>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Headers;
