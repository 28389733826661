import { Navigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  if (!props.token || props.token === "") {
    // If token is not present or empty, navigate to login
    return <Navigate to="/" replace />;
  }

  // If token is present, render the children
  return props.children;
};

export default ProtectedRoute;
