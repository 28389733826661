import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  deleteError: null,
  deleteSuccess: null,
  postError: null,
  postSuccess: null,
  updateError: null,
  updateSuccess: null,
  data: [],
};

const timeLineSlice = createSlice({
  name: "timeLine",
  initialState,
  reducers: {
    timeLineStart(state) {
      state.loading = true;
      state.error = null;
    },

    timeLineSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.result;
      state.success = action.payload.data.message;
    },
    timeLineFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updatetimeLineStart(state) {
      state.loading = true;
      state.updateError = null;
    },

    updatetimeLineSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.updateSuccess = action.payload.data.message;
    },
    updatetimeLineFailure(state, action) {
      state.loading = false;
      state.updateError = action.payload;
    },
    posttimeLineStart(state) {
      state.loading = true;
      state.postError = null;
    },

    posttimeLineSuccess(state, action) {
      state.loading = false;
      state.postSuccess = action.payload.data.message;
    },
    posttimeLineFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
    },
    deletetimeLineStart(state) {
      state.loading = true;
      state.deleteError = null;
    },

    deletetimeLineSuccess(state, action) {
      state.loading = false;
      state.deleteSuccess = action.payload.data.message;
    },
    deletetimeLineFailure(state, action) {
      state.loading = false;
      state.deleteError = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.deleteError = null;
      state.deleteSuccess = null;
      state.postError = null;
      state.postSuccess = null;
      state.updateError = null;
      state.updateSuccess = null;
    },
  },
});

export const {
  timeLineStart,
  timeLineSuccess,
  timeLineFailure,
  updatetimeLineStart,
  updatetimeLineSuccess,
  updatetimeLineFailure,
  deletetimeLineStart,
  deletetimeLineSuccess,
  deletetimeLineFailure,
  posttimeLineStart,
  posttimeLineSuccess,
  posttimeLineFailure,
  clearState,
} = timeLineSlice.actions;

export default timeLineSlice.reducer;
