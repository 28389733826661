import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "./Payment.css";
import QRCodeStyling from "qr-code-styling";
import { v4 as uuidv4 } from "uuid";

import useResponsiveFontSize from "../../useResponsiveFontSize.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getLastQrId,
  getPaymentIntent,
  purchaseQr,
} from "../../Redux/Actions/payment.js";
import { Spin, message } from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import { clearState } from "../../Redux/Reducers/paymentReducer.js";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const PaymentForm = ({ handleClose, frame, handleShowOnBoard }) => {
  const { loading, error, success } = useSelector((state) => state.payment);
  const { data } = useSelector((state) => state.payment);
  const qrRef = useRef(null);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [intent, setIntent] = useState();
  const [link, setLink] = useState();
  const [qrCode, setQrCode] = useState(
    new QRCodeStyling({
      width: 135,
      height: 135,
      dotsOptions: {
        color: frame ? "#fff" : "#fff",
        type: "square",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
      backgroundOptions: null,
      type: "svg",
    })
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(event);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        message.error(error.message);
        return;
      }

      const { error: confirmError, paymentIntent } =
        await stripe.confirmCardPayment(intent, {
          payment_method: paymentMethod.id,
        });

      if (confirmError) {
        message.error(confirmError.message);
        return;
      }

      console.log("[PaymentIntent]", paymentIntent);

      // Handle successful payment here
      await handleSuccess(paymentIntent);
    } catch (error) {
      message.error("An error occurred while processing your payment.");
    }
  };

  console.log(data, "<================data");
  const handleSuccess = async (paymentIntent) => {
    try {
      const blobData = await qrCode.getRawData("png");
      const svgData = await qrCode.getRawData("svg");
      const arrayBuffer = await svgData.arrayBuffer();

      const svgString = new TextDecoder().decode(arrayBuffer);

      const modifiedSvgString = svgString.replace(
        /<path/g,
        '<path fill="#ff0000"'
      );

      // Convert the modified SVG string back to Blob
      const modifiedSvgBlob = new Blob([modifiedSvgString], {
        type: "image/svg+xml",
      });

      const file = new File([blobData], `image_.png`);
      const svgFile = new File([modifiedSvgBlob], `qr.svg`);

      const formData = new FormData();
      formData.append("Link", link);
      formData.append("image", file);
      formData.append("PaymentLink", paymentIntent.id);
      formData.append("svgPath", svgFile);

      formData.append("frame", frame);
      const res = await dispatch(purchaseQr({ formData }));

      if (purchaseQr.fulfilled.match(res)) {
        handleClose();
        message.success(
          "Payment successful and Qr is Generated Thank you for your transaction."
        );
        // handleShowOnBoard();
      }
    } catch (error) {
      message.error(
        "An error occurred while sending payment data to the server."
      );
    }
  };
  const getIntent = async () => {
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/PaymentIntent`,
      config
    );
    setIntent(response.data.clientSecret);
  };
  useEffect(() => {
    getIntent();
  }, [dispatch]);
  useEffect(() => {
    if (qrRef.current && !qrRef.current.firstChild) {
      qrCode.append(qrRef.current);
    }
  }, [qrRef]);

  useEffect(() => {
    const fullUrl = `${process.env.REACT_APP_REDIRECT_URL}${link}`;
    qrCode.update({
      data: fullUrl,
    });
  }, [link]);

  useEffect(() => {
    if (success) {
      message.success(success);
      dispatch(clearState());
    }
  }, [dispatch]);
  const incrementQrIdNumber = (lastQrId) => {
    const numericPart = lastQrId?.slice(-4);
    let newNumber = parseInt(numericPart, 16) + 1;

    newNumber = newNumber.toString(16).padStart(4, "0").toUpperCase();

    return newNumber;
  };
  const generateQrId = async () => {
    const baseUuid = uuidv4();
    const lastQrId = data?.data?._id || "afc06471-a8cf-4dd0-8399-6571fe0200000";

    const newNumber = await incrementQrIdNumber(lastQrId);

    const newQrId = `${baseUuid.substring(0, baseUuid.length - 4)}${newNumber}`;

    console.log(newQrId);
    return newQrId;
  };


  useEffect(() => {
    generateQrId().then((newQrId) => {
      setLink(newQrId);
    });
  }, [data]);
  useEffect(() => {
    dispatch(getLastQrId());
  }, [dispatch]);

  return (
    <div className="DemoWrapper">
      <div className="Demo row">
        <div style={{ display: "none" }} ref={qrRef} className="qr"></div>

        <form onSubmit={handleSubmit}>
          <div className="col-sm-12">
            <label>
              Card number
              <CardNumberElement
                options={options}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </label>
          </div>
          <div className="col-sm-12">
            <label>
              Expiration date
              <CardExpiryElement
                options={options}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </label>
          </div>
          <div className="col-sm-12">
            <label>
              CVC
              <CardCvcElement
                options={options}
                onReady={() => {
                  console.log("CardNumberElement [ready]");
                }}
                onChange={(event) => {
                  console.log("CardNumberElement [change]", event);
                }}
                onBlur={() => {
                  console.log("CardNumberElement [blur]");
                }}
                onFocus={() => {
                  console.log("CardNumberElement [focus]");
                }}
              />
            </label>
          </div>
          <div className="col-sm-12">
            {!loading ? (
              <button type="submit" disabled={loading}>
                Pay
              </button>
            ) : (
              <button style={{ background: "#B4B4B4" }}>
                <Spin />
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
