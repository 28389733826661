import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
  token: null, // Add token to the initial state
};

const resetCodeSlice = createSlice({
    name: "resetCode",
    initialState,
    reducers: {
        resetCodeStart(state) {
            state.loading = true;
            state.error = null;
            state.success = null;
        },

        resetCodeSuccess(state, action) {
            state.loading = false;
            state.isAuthenticated = true;
            state.success = action.payload.message;
            state.token = action.payload.Token; // Store the token
        },
        logoutresetCodeSuccess(state, action) {
            state.loading = false;
            state.data = {};
            state.success = action.payload;
            state.token = null; // Clear the token
        },

        resetCodeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.success = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearsuccess: (state) => {
            state.success = null;;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.token = null; // Clear the token
            state.data = {}; // Clear the data if needed
        },
    },
});

export const {
    resetCodeStart,
    resetCodeSuccess,
    logoutresetCodeSuccess,
    resetCodeFailure,
    clearError,
    clearsuccess,
    clearState,
} = resetCodeSlice.actions;

export default resetCodeSlice.reducer;