import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  tributeSettingFailure,
  tributeSettingStart,
  tributeSettingSuccess,
  updateTributeSettingFailure,
  updateTributeSettingStart,
  updateTributeSettingSuccess,
} from "../Reducers/tributeSettingReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const getTributeSetting = createAsyncThunk(
  "auth",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(tributeSettingStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/GetUserTributeSettings/${id}`,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(tributeSettingSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(tributeSettingFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updateTributeSetting = createAsyncThunk(
  "auth",
  async ({ id, formData }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateTributeSettingStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserTributeNameRoutes/UpdateUserTributeSettings/${id}`,
        formData,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updateTributeSettingSuccess(responseData));
      await thunkAPI.dispatch(getTributeSetting(id));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        updateTributeSettingFailure(error.response.data.message)
      );

      throw error;
    }
  }
);
