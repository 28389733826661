import { createAsyncThunk } from "@reduxjs/toolkit";
import { resetCodeStart, resetCodeSuccess, resetCodeFailure } from "../Reducers/resetCodeReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const resetCode = createAsyncThunk(
    "resetCode",
    async ({ values }, thunkAPI) => {
        try {
          thunkAPI.dispatch(resetCodeStart());
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/ConfirmCode`,
            values
          );
          const token = response.data.token; // Assuming the token is in response.data.token
          thunkAPI.dispatch(resetCodeSuccess(response.data));
          Cookies.set("token", response.data.Token);
        } catch (error) {
          thunkAPI.dispatch(resetCodeFailure(error.response.data.message)); // Dispatch the failure action
          return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
        }
    }
);