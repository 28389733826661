import { Button, Form, Input, message, Radio, Upload } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { postMedia } from "../../../Redux/Actions/media";
import { clearState } from "../../../Redux/Reducers/mediaReducer";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import CustomCalender from "../CustomCalender";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const UploadPhotoVideoModal = ({ handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [mediaType, setMediaType] = useState("image");
  const [selectedDate, setSelectedDate] = useState();
  const { postError, postSuccess, loading } = useSelector(
    (state) => state.media
  );
  const onFinish = (value) => {
    const values = new FormData();
    if (value.Description) {
      values.append("Description", value.Description);
    }
    if (value.VideoUrl) {
      values.append("VideoUrl", value.VideoUrl);
    }
    if (selectedDate) {
      values.append("Date", selectedDate);
    }
    const file = value.file?.fileList[0]?.originFileObj;
    if (file) {
      values.append("Media", file);
    }

    dispatch(postMedia({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleMediaChange = (e) => {
    setMediaType(e.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    if (postError) {
      message.error(postError);
      dispatch(clearState());
    }
    if (postSuccess) {
      message.success(postSuccess);
      dispatch(clearState());
      handleClose();
    }
  }, [postError, postSuccess]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
        >
          <div className="row uploadImageForm">
            <div className="col-sm-12 uploadHeading">
              <p>{t("uploadImage")}</p>
            </div>
            <div className="col-sm-12">
              <Form.Item>
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  onChange={handleMediaChange}
                  value={mediaType}
                >
                  <Radio.Button value="image">{t("image")}</Radio.Button>
                  <Radio.Button value="video"> {t("video")}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Form.Item 
                name="Description" 
                label={t("description")}
                rules={[
                  {
                    required: true,
                    message: `${t("please_enter_description")}`,
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder={t("description")} />
              </Form.Item>
            </div>

            <div className="col-sm-12 datee">
              <Form.Item name="eventDate" label={t("eventDate")}>
                <DatePicker
                  value={selectedDate}
                  selected={selectedDate ? selectedDate : null}
                  isClearable
                  onChange={handleDateChange}
                  placeholderText={t("eventDate")}
                  dateFormat="MMMM d, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  renderCustomHeader={CustomCalender}
                />{" "}
              </Form.Item>
            </div>
            {mediaType === "image" && (
              <div className="col-sm-12">
                <Form.Item
                  name="file"
                  label={t("uploadImage")}
                  rules={[
                    {
                      required: true,
                      message: `${t("pleaseUploadImage")}`,
                    },
                  ]}
                >
                  <Upload
                    action="/upload.do"
                    beforeUpload={() => false}
                    accept="image/*"
                    name="logo"
                    maxCount={1}
                    listType="picture-card"
                  >
                    <Button icon={<UploadOutlined />}>
                      {" "}
                      {t("clickToUpload")}
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            )}

            {mediaType === "video" && (
              <div className="col-sm-12 videoLink">
                <Form.Item
                  name="VideoUrl"
                  label={t("youtubeURL")}
                  rules={[
                    {
                      required: true,
                      message: `${t("addVideoLink")}`
                    },
                  ]}
                >
                  <Input placeholder={t("youtubeURL")} />
                </Form.Item>
              </div>
            )}

            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : t("save")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default UploadPhotoVideoModal;
