import React from 'react'
import { NavLink, useParams } from "react-router-dom";
import bioWhite from "../../../Assets/bioWhite.png";
import bioGray from "../../../Assets/bioGray.png";
import photoWhite from "../../../Assets/photoWhite.png";
import photoGray from "../../../Assets/photoGray.png";
import timelineWhite from "../../../Assets/timelineWhite.png";
import timelineGray from "../../../Assets/timelineGray.png";
import tributeWhite from "../../../Assets/tributeWhite.png";
import tributeGray from "../../../Assets/tributeGray.png";
import { useTranslation } from "react-i18next";

const FooterNav = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  return (
    <div className="row centers">
      <div className="col-sm-12 widthSeventy">
        <div className="row noMarrrgin">
          <div className="col-sm-2">
            <NavLink to={`/pages/bio/${id}`} activeClassName="navLinkks">
              <div>
                <img width={21} src={bioWhite} className="yeeess" />
                <img width={21} src={bioGray} className="nooo" />
              </div>
              {t("bio")}
            </NavLink>
          </div>
          <div className="col-sm-2">
            <NavLink to={`/pages/photoss/${id}`} activeClassName="navLinkks">
              <div>
                <img width={21} src={photoWhite} className="yeeess" />
                <img width={21} src={photoGray} className="nooo" />
              </div>
              {/* {footerNav?.photos} */}
              {t("photos")}
            </NavLink>
          </div>
          <div className="col-sm-2">
            <NavLink to={`/pages/timeline/${id}`} activeClassName="navLinkks">
              <div>
                <img width={21} src={timelineWhite} className="yeeess" />
                <img width={21} src={timelineGray} className="nooo" />
              </div>
              {t("timeline")}
            </NavLink>
          </div>
          <div className="col-sm-2">
            <NavLink to={`/pages/tributes/${id}`} activeClassName="navLinkks">
              <div>
                <img width={21} src={tributeWhite} className="yeeess" />
                <img width={21} src={tributeGray} className="nooo" />
              </div>
              {/* {footerNav?.tributes} */}
              {t("tributes")}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNav
