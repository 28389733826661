import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetCode from "./Pages/ResetCode";
import NewPassword from "./Pages/NewPassword";
import QRManagement from "./Pages/QRManagement";
import BioPage from "./Pages/BioPage";
import PhotossPage from "./Pages/PhotossPage";
import TimeLinePage from "./Pages/TimeLinePage";
import Tributte from "./Pages/Tributte";
import PersonalDetail from "./Pages/PersonalDetail";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import EnterData from "./Pages/EnterData";
import MyTributes from "./Pages/MyTributes";
import FAQ from "./Pages/FAQ";
import Authentication from "./Pages/Authentication";
import { useEffect } from "react";

function App() {
  const { token, isAuthenticated } = useSelector((state) => state.user);
  const { signUpToken } = useSelector((state) => state.postProfile);
  const userToken = Cookies.get("token");
  const tokens = token ? token : signUpToken ? signUpToken : userToken;
 

  return (
    <Routes>
      <Route exact path="/" element={<EnterData />} />
      <Route exact path="/:id" element={<Authentication />} />
      <Route exact path="/reset" element={<ForgetPassword />} />
      <Route exact path="/reset-code" element={<ResetCode />} />
      <Route exact path="/reset-password" element={<NewPassword />} />
      <Route
        exact
        path="/pages"
        element={
          <ProtectedRoute token={tokens}>
            <QRManagement />
          </ProtectedRoute>
        }
      />
      <Route exact path="/my-tributes" element={<MyTributes />} />
      <Route exact path="/pages/bio/:id" element={<BioPage />} />
      <Route exact path="/pages/photoss/:id" element={<PhotossPage />} />
      <Route exact path="/pages/timeline/:id" element={<TimeLinePage />} />
      <Route exact path="/pages/tributes/:id" element={<Tributte />} />
      <Route exact path="/profile" element={<PersonalDetail />} />
      <Route exact path="/faq" element={<FAQ />} />
    </Routes>
  );
}

export default App;
