import { Button, Form, Input, Radio, Space, Switch, message } from "antd";
import DatePicker from "react-datepicker";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTributeSetting,
  updateTributeSetting,
} from "../../../Redux/Actions/tributeSetting";
import moment from "moment";
import { clearState } from "../../../Redux/Reducers/tributeSettingReducer";
import { useParams } from "react-router-dom";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import CustomCalender from "../CustomCalender";

const { RangePicker } = DatePicker;

const TributeSettingForm = ({
  handleTributeFalse,
  handleTributeTrue,
  handleClose,
  handleTributeImageUpload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const { data, editSuccess, editError, loading } = useSelector(
    (state) => state.tributeSetting
  );
  const { id } = useParams();
  const [allowRadio, setAllowRadio] = useState();
  useEffect(() => {
    dispatch(getTributeSetting(id));
  }, [dispatch]);
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        tributeAllow: data.AllowSettings,
        // tributeTime: data.StartDate
        //   ? [moment(data.StartDate), moment(data.EndDate)]
        //   : null,
        isAllow: data.AllowToUploadPicture,
      });
    }
    setSelectedDate(data.StartDate ? moment(data.StartDate).toDate() : null);
    setSelectedEndDate(data.EndDate ? moment(data.EndDate).toDate() : null);
  }, [data, form]);

  const onFinish = (values) => {
    // const tributeData = {
    //   AllowSettings: values.tributeAllow,
    //   AllowToUploadPicture: values.isAllow,
    //   StartDate: values.tributeTime
    //     ? values.tributeTime[0].toISOString()
    //     : null,
    //   EndDate: values.tributeTime ? values.tributeTime[1].toISOString() : null,
    // };
    const formData = new FormData();
    formData.append("AllowSettings", values.tributeAllow);
    formData.append("AllowToUploadPicture", values.isAllow);
    formData.append("StartDate", selectedDate);
    formData.append("EndDate", selectedEndDate);
    dispatch(updateTributeSetting({ id, formData }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSwitchChange = () => {
    handleTributeImageUpload();
  };

  const onRadioChange = (e) => {
    form.setFieldsValue({ tributeAllow: e.target.value });
    setAllowRadio(e.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateChange1 = (date) => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    if (
      form.getFieldValue("tributeAllow") === "Always Allow" ||
      form.getFieldValue("tributeAllow") === "Allow Between Dates"
    ) {
      handleTributeTrue();
    } else {
      handleTributeFalse();
    }
  }, [form, allowRadio]);

  useEffect(() => {
    if (editError) {
      message.error(editError);
      dispatch(clearState());
    }
    if (editSuccess) {
      message.success(editSuccess);
      dispatch(clearState());
      handleClose();
    }
  }, [editError, editSuccess]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          form={form}
        >
          <div className="row uploadImageForm">
            <div className="col-sm-12 uploadHeading">
              <p>{t("tributeSetting")}</p>
            </div>

            <div className="col-sm-12 uploadHeading">
              <p className="subdetail">{t("tributeSettingDescription")}</p>

              <Form.Item name="tributeAllow" label={t("allowSetting")}>
                <Radio.Group onChange={onRadioChange}>
                  <Space direction="vertical">
                    <Radio value="Always Allow">{t("alwaysAllow")}</Radio>
                    <Radio value="Allow Between Dates">
                      {t("allowBWDates")}
                    </Radio>
                    <Radio value="Never Allow">{t("neverAllow")}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>

            {form.getFieldValue("tributeAllow") === "Allow Between Dates" && (
              <>
                {/* <div className="col-sm-12 rangee">
                  <Form.Item
                    name="tributeTime"
                    label={t("selectDateRange")}
                    rules={[
                      {
                        required: true,
                        message: t("pleaseSelectDateRange"),
                      },
                    ]}
                  >
                    <RangePicker />
                  </Form.Item>
                </div> */}
                <div className="col-sm-6 rangee">
                  {" "}
                  <Form.Item
                    name="tributeStartTime"
                    label={t("Start Date")}
                  
                  >
                    <DatePicker
                      selected={selectedDate}
                      isClearable
                      onChange={handleDateChange}
                      placeholderText={t("Start Date")}
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      renderCustomHeader={CustomCalender}
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-6 rangee">
                  {" "}
                  <Form.Item
                    name="tributeEndTime"
                    label={t("End Date")}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (
                            !value ||
                            (selectedDate && value > selectedDate)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              t("Date of Start Date cannot be before End Date")
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      selected={selectedEndDate}
                      isClearable
                      onChange={handleDateChange1}
                      placeholderText={t("End Date")}
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      dropdownMode="select"
                      renderCustomHeader={CustomCalender}
                    />
                  </Form.Item>
                </div>

                <div className="col-sm-12">
                  <Form.Item
                    name="isAllow"
                    label={t("allowOthersToUploadPhotos")}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={t("yes")}
                      unCheckedChildren={t("no")}
                      onChange={onSwitchChange}
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {form.getFieldValue("tributeAllow") === "Always Allow" && (
              <div className="col-sm-12">
                <Form.Item
                  name="isAllow"
                  label={t("allowOthersToUploadPhotos")}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onSwitchChange}
                  />
                </Form.Item>
              </div>
            )}

            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit">
                {loading ? <Loader /> : t("save")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default TributeSettingForm;
