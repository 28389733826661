import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editMyProfileFailure,
  editMyProfileStart,
  editMyProfileSuccess,
} from "../Reducers/editMyProfileReducer";
import Cookies from "js-cookie";
import axios from "axios";
import { getMyProfile } from "./getMyProfile";
export const editMyProfile = createAsyncThunk(
  "auth",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(editMyProfileStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/EditUserInfoByToken`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(editMyProfileSuccess(responseData));
      await thunkAPI.dispatch(getMyProfile());

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(editMyProfileFailure(error.response.data.message));
      throw error;
    }
  }
);
