import React, { Fragment, useEffect } from 'react'
import logo from '../../Assets/logo.png'
import { Button, Form, Input, message } from 'antd'
import frwd from '../../Assets/rightArrow.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetCode } from '../../Redux/Actions/resetCode'
import { clearState } from '../../Redux/Reducers/resetCodeReducer'
import Cookies from 'js-cookie';
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const ResetCodeForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { error, success, token, loading } = useSelector(
    (state) => state.resetCode
  );
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    await dispatch(resetCode({ values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);

      // Save token in cookie
      if (token) {
        Cookies.set("token", token, {
          expires: 7,
          domain: "https://legacyhonored.com/",
        });
      }

      navigate("/reset-password");
      dispatch(clearState());
    }
  }, [error, success, token, dispatch]);

  return (
    <Fragment>
      <div className="container-fluid loginForm">
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 cennn mainPageLogo">
              <img src={logo} alt="asd" />
            </div>

            <div className="col-sm-12 whiteBorder">
              <Form.Item
                name="Code"
                label={t("Reset Code")}
                rules={[
                  {
                    required: true,
                    message: t("Please enter code!"),
                  },
                ]}
              >
                <Input placeholder={t("Reset Code")} />
              </Form.Item>
            </div>
            <div className="col-sm-12">
              <Button disabled={loading} htmlType="submit" type="primary">
                {" "}
                {loading ? <Loader /> : "Reset"} <img src={frwd} alt="asd" />
              </Button>
            </div>
            <div className="col-sm-12 cennn">
              <p>
                {t("Do you want to Login?")}
                <span
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/")}
                >
                  {t("Login")}
                </span>
              </p>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default ResetCodeForm
