import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  intent: null,
  data: null,
};

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    purchaseQrStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    purchaseQrSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    purchaseQrFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    paymentIntentStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    paymentIntentSuccess(state, action) {
      state.loading = false;
      state.intent = action.payload;
      state.success = action.payload.data.message;
    },
    paymentIntentFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getLastQrIdStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getLastQrIdSuccess(state, action) {
      state.loading = false;
      state.success = action.payload.data.message;
      state.data = action.payload;
    },
    getLastQrIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  purchaseQrStart,
  purchaseQrSuccess,
  purchaseQrFailure,
  paymentIntentStart,
  paymentIntentSuccess,
  paymentIntentFailure,
  getLastQrIdStart,
  getLastQrIdSuccess,
  getLastQrIdFailure,
  clearState,
} = paymentSlice.actions;

export default paymentSlice.reducer;
