import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ setModalOpen, modalOpen, handleDelete, id }) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    handleDelete(id);
    setModalOpen(false);
  };

  return (
    <Modal
      className="deleteModal"
      title={t("confirmDelete")}
      centered
      open={modalOpen}
      onOk={handleSubmit}
      onCancel={() => setModalOpen(false)}
    ></Modal>
  );
};

export default DeleteModal;
