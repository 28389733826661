import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  authLoginStart,
  authLoginSuccess,
  authLoginFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateProfileSuccess,
  updateProfileFailure,
  updateProfileStart,
  changePasswordFailure,
  changePasswordSuccess,
  changePasswordStart,
  qrAuthenticationStart,
  qrAuthenticationSuccess,
  qrAuthenticationFailure,
  getQrDetailsStart,
  getQrDetailsSuccess,
  getQrDetailsFailure,
} from "../Reducers/authReducer";
import Cookies from "js-cookie";

import axios from "axios";
import { getMyProfile } from "./getMyProfile";
export const Login = createAsyncThunk("auth", async (values, thunkAPI) => {
  try {
    thunkAPI.dispatch(authLoginStart());

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/LoginUser`,
      values
    );
    const responseData = await response;

    thunkAPI.dispatch(authLoginSuccess(responseData));
    Cookies.set("token", responseData.data.data);

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(authLoginFailure(error.response.data.message));
    thunkAPI.dispatch(getMyProfile());

    throw error;
  }
});
export const getMyDetails = createAsyncThunk(
  "auth",
  async ({ id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getMyDetailsStart());
      const token = Cookies.get("token");


      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}UserProfileRoutes/GetProfileByUser/${id}`);
      const responseData = await response;
      thunkAPI.dispatch(getMyDetailsSuccess(responseData));
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getMyDetailsFailure(error.response.data.message));

      throw error;
    }
  }
);
export const updateProfile = createAsyncThunk(
  "auth",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateProfileStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}auth/Edit`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(updateProfileSuccess(responseData));
      thunkAPI.dispatch(getMyDetails());
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(updateProfileFailure(error.response.data.message));

      throw error;
    }
  }
);
export const changePassword = createAsyncThunk(
  "auth",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(changePasswordStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/ChangePassword`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(changePasswordSuccess(responseData));
      thunkAPI.dispatch(getMyDetails());
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(changePasswordFailure(error.response.data.message));

      throw error;
    }
  }
);

export const QrAuthentication = createAsyncThunk(
  "auth",
  async (id, thunkAPI) => {
    try {
      thunkAPI.dispatch(qrAuthenticationStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UserQrRoutes/QRAuth/${id}`,
        config
      );
      const responseData = await response;
      thunkAPI.dispatch(qrAuthenticationSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(qrAuthenticationFailure(error.response.data.message));

      throw error;
    }
  }
);
export const getQrDetail = createAsyncThunk("auth", async (id, thunkAPI) => {
  try {
    thunkAPI.dispatch(getQrDetailsStart());
    const token = Cookies.get("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/UserDetailByQrCode/${id}`
    );
    const responseData = await response;
    thunkAPI.dispatch(getQrDetailsSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(getQrDetailsFailure(error.response.data.message));

    throw error;
  }
});