import { Button, Form, Input, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postTribute, postTributeImage } from "../../../Redux/Actions/tributes";
import { clearState } from "../../../Redux/Reducers/tributeReducer";
import { useParams } from "react-router-dom";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const AddTributeForm = ({
  tributeImageUpload,
  handleClose,
  handleShowImage,
  isAuthenticated,
  AllowToUploadPicture,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { id } = useParams();

  const { postError, postSuccess, postData, loading } = useSelector(
    (state) => state.tribute
  );
  const onFinish = (values) => {
    dispatch(postTribute({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (postError) {
      message.error(postError);
      dispatch(clearState());
    }
    if (postSuccess) {
      message.success(postSuccess);
      dispatch(clearState());
      if (isAuthenticated == "true") {
        handleShowImage();
      }
      if (AllowToUploadPicture) {
        handleShowImage();
      }
      handleClose();
    }
  }, [postError, postSuccess]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          form={form}
          autoComplete="off"
        >
          <div className="row uploadImageForm">
            <div className="col-sm-12 uploadHeading">
              <p>{t("add_tribute")}</p>
            </div>

            <div className="col-sm-12 uploadHeading">
              <p className="subdetail">
                {t(
                  "capture_your_thoughts_about_write_your_feelings_or_share_a_memory"
                )}
              </p>
            </div>

            <div className="col-sm-12 videoLink">
              <Form.Item
                name="TributeName"
                label={t("name")}
                rules={[
                  {
                    required: true,
                    message: t("please_add_name"),
                  },
                ]}
              >
                <Input placeholder={t("name")} />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="Description"
                label={t("tribute")}
                rules={[
                  {
                    required: true,
                    message: t("please_add_tribute"),
                  },
                ]}
              >
                <Input.TextArea rows={7} placeholder={t("tribute")} />
              </Form.Item>
            </div>
            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit">
                {loading ? (
                  <Loader />
                ) : AllowToUploadPicture ? (
                  t("Add Images")
                ) : (
                  t("Save&Close")
                )}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddTributeForm;
