import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    loading: false,
    success: null,
    error: null,
    data: {},
};

const forgetPasswordSlice = createSlice({
    name: "forgetPassword",
    initialState,
    reducers: {
        forgetPasswordStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        forgetPasswordSuccess(state, action) {
            state.loading = false;
            state.isAuthenticated = true;
            state.success = action.payload.message;
        },
        logoutForgetPassSuccess(state, action) {
            state.loading = false;
            state.data = {};
            state.success = action.payload;
            state.token = null;
        },
        forgetPasswordFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.success = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearsuccess: (state) => {
            state.success = null;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.passwordSuccess = null;
            state.passwordError = null;
        },
    },
});

export const {
    forgetPasswordStart,
    forgetPasswordSuccess,
    logoutForgetPassSuccess,
    forgetPasswordFailure,
    clearError,
    clearsuccess,
    clearState,
} = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;