import { Button, Form, Radio } from 'antd'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

const SelectFrame = ({ handleModalOpen, handleFrameModalClose, setFrame }) => {
  const onFinish = (values) => {
    setFrame(values.QR);
    handleModalOpen();
    handleFrameModalClose();
  };

  const {t} = useTranslation();

  const onFinishFailed = (values) => {
    console.log(values);
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <div className="col-sm-12">
              <Form.Item
                name="QR"
                rules={[
                  {
                    required: true,
                    message: t("pleaseSelectAnyQr"),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={false}>
                    <img
                      className="QRRadio"
                      src="https://junzhiassets.s3.amazonaws.com/StaticData/squareframe1.svg"
                    />
                  </Radio>
                  <Radio value={true}>
                    <img
                      className="QRRadio"
                      src="https://junzhiassets.s3.amazonaws.com/StaticData/squareframe2.svg"
                    />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="col-sm-12 submitt qrprevieews">
              <Button htmlType="submit" type="primary">
                {t("select")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectFrame
