import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { QrList } from "../../Redux/Actions/qrList";
import { Button, Spin } from "antd";
import { Modal } from "react-bootstrap";
import PurchaseModal from "./PurchaseModal";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { IoDocument } from "react-icons/io5";
import moment from "moment";
import SelectFrame from "../QRDetail/SelectFrame";
import OnboardingSteps from "../OnBoarding/OnboardingSteps";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";

const Listing = () => {
  
  const { t } = useTranslation();
  const [format, setFormat] = useState("jpeg");
  const { state } = useLocation();
  const [frame, setFrame] = useState(false);
  const stripePromise = loadStripe(
    "pk_test_51NaHE9ElW1Dvtp1O01PSNQnox9j1XQ5jfjLCNQTWn1CmNlCZw38JxoDbDZ8U7ut9mkFMUqdFamSkUYax3wOEEIQz00m65OcohO"
  );
  const [showOnBoard, setShowOnBoard] = useState(false);
  const handleShowOnBoard = () => setShowOnBoard(true);
  const handleCloseOnBoard = () => setShowOnBoard(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpenDetail, setModalOpenDetail] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [frameModal, setFramModal] = useState(false);
  const handleFrameModalOpen = () => {
    setFramModal(true);
  };
  const handleFrameModalClose = () => {
    setFramModal(false);
  };

  const { data, loading } = useSelector((state) => state.userqr);
  useEffect(() => {
    dispatch(QrList());
    console.log(loading, '<============loading')
  }, [dispatch]);
  const handleModalOpenDetail = () => {
    setModalOpenDetail(!modalOpenDetail);
  };
  const handleClose = () => setModalOpenDetail(false);
  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (state && state.isOpen == true) {
      handleShowOnBoard();
      state.isOpen = false;
    }
  }, [state]);
  const handleClosePayment = () => setModalOpen(false);

  return (
    <Fragment>
      <div className="container-fluid qrr">
        <div className="row noPad qrListingNew">
          {loading ? (
            <div className="loading">
              <Spin
                style={{ color: "#87562E" }}
                indicator={<LoadingOutlined spin style={{ fontSize: 100 }} />}
              />
            </div>
          ) : (
            <>
              {data && data?.length > 0 ? (
                data?.map((item) => (
                  <div
                    key={item.QrId}
                    className="col-sm-3 lsistiny"
                    onClick={() => navigate(`/pages/bio/${item.QrId}`)}
                  >
                    {item?.UserQr?.QrProfileData?.Name &&
                    item?.UserQr?.QrProfileData?.ProfilePic ? (
                      <>
                        <img
                          src={item.UserQr?.QrProfileData?.ProfilePic}
                          alt="profile"
                        />
                        <div className="qrdetail">
                          <p>{item.UserQr?.QrProfileData?.Name}</p>
                          <p>
                            {moment(item.UserQr?.QrProfileData?.DOB).format(
                              "MMM Do YYYY"
                            )}{" "}
                            -{" "}
                            {moment(item.UserQr?.QrProfileData?.DOD).format(
                              "MMM Do YYYY"
                            )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <img src={item.UserQr?.QrMedia} alt="qrcode" />
                    )}
                  </div>
                ))
              ) : (
                <div className="col-sm-12 noPad noDAtata">
                  <div className="row noQRsListed">
                    <div className="col-sm-12">
                      <IoDocument color="#87562E" className="svgSize" />
                    </div>
                    <div className="col-sm-12">
                      <p>{t("noPages")}</p>{" "}
                    </div>

                    <div className="col-sm-12">
                      <Button className="brownBgNewLink">
                        <a
                          target="_blank"
                          href="https://amazon.com/dp/B0DDT3HVTQ"
                        >
                          {t("purchaseMemorialPlague")}
                        </a>
                      </Button>
                    </div>
                    <div className="col-sm-12 brownBorder etsyy">
                      <Button onClick={handleModalOpenDetail}>
                        {t("Create A Digital Page")}{" "}
                      </Button>
                    </div>
                    <div className="col-sm-12">
                      <Button className="brownBgNewLink">
                        <a
                          target="_blank"
                          href="https://www.etsy.com/listing/1787318192/memorial-webpage-and-qr-code-memorial"
                        >
                          {t("For customers outside of the USA, order on Etsy")}
                        </a>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        show={modalOpenDetail}
        onHide={handleClose}
        className="purchaseModal purchaseModalmainn"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h5> {t("purchaseDigitalPage")}</h5>
        </Modal.Header>

        <Modal.Body>
          <PurchaseModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            handleClose={handleClosePayment}
            handleCloseDetail={handleClose}
            handleModalOpen={handleModalOpen}
            handleFrameModalOpen={handleFrameModalOpen}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={frameModal}
        onHide={handleFrameModalClose}
        className="purchaseModal"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <h5>{t("selectQrFrame")}</h5>
        </Modal.Header>
        <Modal.Body>
          <SelectFrame
            frame={frame}
            setFrame={setFrame}
            handleModalOpen={handleModalOpen}
            handleFrameModalClose={handleFrameModalClose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalOpen}
        onHide={handleClosePayment}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Elements stripe={stripePromise}>
            <PaymentForm frame={frame} handleClose={handleClosePayment} />
          </Elements>
        </Modal.Body>
      </Modal>

      <Modal
        show={showOnBoard}
        onHide={handleCloseOnBoard}
        className="detailTimelineModal onboardiingModal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <OnboardingSteps handleClose={handleCloseOnBoard} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Listing;
