import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
};

const getMyProfileSlice = createSlice({
  name: "getMyProfile",
  initialState,
  reducers: {
    getMyProfileStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyProfileSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    getMyProfileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  getMyProfileStart,
  getMyProfileSuccess,
  getMyProfileFailure,
  clearState,
} = getMyProfileSlice.actions;

export default getMyProfileSlice.reducer;
