import { Button, Form, Input, message } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../Redux/Actions/auth';
import { clearState } from '../../Redux/Reducers/authReducer';
import { useTranslation } from 'react-i18next';


const ChangePassword = ({ handleClose }) => {
    // OldPassword, Password, ConfirmPassword
    const dispatch = useDispatch();
    const {passwordSuccess, passwordError} = useSelector((state) => state.user)

    const validatePassword = (_, value) => {
        if (value && value.length < 4) {
          return Promise.reject("Password must be at least 4 characters long.");
        }
        return Promise.resolve();
    };

    const onFinish = async(values) => {
        if(values.Password === values.ConfirmPassword)
        {        
            await dispatch(changePassword(values));
        }
    };

    useEffect(() => {
        if (passwordError) {
          message.error(passwordError);
          dispatch(clearState());
        }
        if (passwordSuccess) {
          message.success(passwordSuccess);
          dispatch(clearState());
          handleClose();
        }
      }, [passwordSuccess, passwordError]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const {t} = useTranslation();

    return (
        <Fragment>
            <div className="container-fluid">
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                >
                    <div className="row uploadImageForm changePass">
                        <div className="col-sm-12 uploadHeading">
                            <p>{t("changePassword")}</p>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                name="OldPassword"
                                label={t("oldPassword")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("pleaseEnterOldPassword")}`
                                    }
                                ]}
                            >
                                <Input.Password placeholder={t("oldPassword")} />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                name="Password"
                                label={t("newPassword")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("pleaseEnterNewPassword")
                                    },
                                    { 
                                        validator: validatePassword },
                                ]}
                            >
                                <Input.Password placeholder={t("newPassword")} />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                name="ConfirmPassword"
                                label={t("confirmPassword")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("pleaseEnterConfirmPassword")
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("Password") === value) {
                                            return Promise.resolve();
                                            }
                                            return Promise.reject(`${t("theTwoPasswordsDoNotMatch")}`);
                                        },
                                    }),
                                  ]}
                  
                            >
                                <Input.Password placeholder={t("confirmPassword")} />
                            </Form.Item>
                        </div>



                        <div className="col-sm-12 submitt">
                            <Button type='primary' htmlType='submit'>{t("save")}</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    );
}

export default ChangePassword;
