import { Button, Tooltip, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DeleteModal from "../../DeleteModal/DeleteModal";
import AddNewTimelineData from "./AddNewTimelineData";
import EditTimelineData from "./EditTimelineData";
import { useDispatch, useSelector } from "react-redux";
import { deleteTimeline, getTimeLine } from "../../../Redux/Actions/timeLine";
import { clearState } from "../../../Redux/Reducers/timeLineReducer";
import Pagination from "../../Pagination/Pagination";
import TimeLineDetail from "./TimeLineDetail";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const Timeline = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const handleShow = () => {
    setShowManage(false);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const handleShowManage = () => setShowManage(true);
  const handleCloseManage = () => setShowManage(false);
  const { data, loading } = useSelector((state) => state.timeline);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [detailData, setDetailData] = useState();

  const handleShowEdit = (data) => {
    setShowManage(false);
    setEditData(data);
    setShowEdit(true);
  };
  const isAuthenticated = Cookies.get("isAuthenticated")

  const [detailShow, setDetailShow] = useState(false);

  const handleDetailShow = (data) => {
    setDetailData(data);
    setDetailShow(true);
  };
  const handleCloseDetail = () => setDetailShow(false);
  const handleCloseEdit = () => {
    dispatch(getTimeLine({ id, page, pageSize }));

    setShowEdit(false);
  };
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [ids, setId] = useState(editData);
  const [modalOpen, setModalOpen] = useState(false);
  const { deleteError, deleteSuccess } = useSelector((state) => state.timeline);
  const handleDelete = async (id) => {
    dispatch(deleteTimeline(id));
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (deleteError) {
      message.error(deleteError);
      dispatch(clearState());
    }
    if (deleteSuccess) {
      message.success(deleteSuccess);
      dispatch(clearState());
    }
  }, [deleteError, deleteSuccess]);

  useEffect(() => {
    dispatch(getTimeLine({ id, page, pageSize }));
  }, [page, pageSize]);

  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(!modalOpen);
  };

  const getWordCount = (htmlString) => {
    if (!htmlString) return 0;
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const text = div.textContent || div.innerText || "";
    return text.split(/\s+/).filter((word) => word.length > 0).length;
  };

  const truncateText = (htmlString, wordLimit) => {
    if (!htmlString) return "";
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const text = div.textContent || div.innerText || "";
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <Fragment>
      <div className="container-fluid qrr timelineeee">
        <div className="row noMArr fform">
          <div className="col-sm-12 topAPd widthSeventy">
            <div className="row mrginNo">
              <div className="col-sm-6"></div>
              {isAuthenticated == "true" && (
                <div className="col-sm-6 formRight timelineTable">
                  <Button onClick={handleShowManage}>
                    {t("manageTimeline")}
                  </Button>
                </div>
              )}

              <div className="col-sm-12 boxxeess timeline ">
                {data?.items?.map((event, index, array) => (
                  <div
                    className={`row forImage timeline-container ${
                      index === array.length - 1 ? "lastElement" : ""
                    }`}
                    key={index}
                  >
                    {index % 2 === 0 ? (
                      <>
                        <div className="col-sm-6"></div>
                        <div className=" col-sm-6 ddivvs timeline-content llefdfft">
                          <div className="row">
                            <div className="col-sm-2">
                              <div className="year">
                                <p> {new Date(event?.Time).getFullYear()}</p>
                              </div>
                            </div>
                            <div className="col-sm-10">
                              <div>
                                <h2>{event.EventName}</h2>
                                <p className="notShow">{truncateText(event.Description, 40)}</p>
                                  <p className="read" onClick={() => handleDetailShow(event)}>{t("readMore")}</p>
                                {/* {getWordCount(event.Description) > 40 && ( */}
                                {/* )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-sm-6 ddivvs timeline-content mrrightt">
                          <div className="row">
                            <div className="col-sm-2">
                              <div className="year">
                                <p> {new Date(event?.Time).getFullYear()}</p>
                              </div>
                            </div>
                            <div className="col-sm-10">
                              <div>
                                <h2>{event.EventName}</h2>
                                <p className="notShow">{truncateText(event.Description, 40)}</p>
                                <p className="read" onClick={() => handleDetailShow(event)} >{t("readMore")}</p>
                                {/* {getWordCount(event.Description) > 40 && ( */}
                                {/* )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6"></div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {data?.totalCount > 10 && ( 
              <Pagination
                TotalData={data?.totalCount}
                handlePageChange={handlePageChange}
                currentPage={page}
              />
            )}
            
          </div>
        </div>
      </div>

      <Modal
        show={showManage}
        onHide={handleCloseManage}
        className="detailTimelineModal"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6"></div>
              <div className="col-sm-6 formRight timelineTable">
                <Button onClick={handleShow}>{t("add")}</Button>
              </div>
              {data?.items?.map((item, i) => (
                <div
                  className="col-sm-12 timeDetail"
                  onClick={() => handleShowEdit(item)}
                  key={i}
                >
                  <Tooltip placement="top" title={t("editEvent")}>
                    <p>
                      {moment(item?.Time).format("YYYY")} - {item?.EventName}
                    </p>
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={detailShow}
        onHide={handleCloseDetail}
        className="detailTimelineModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <TimeLineDetail handleClose={handleCloseDetail} data={detailData} />
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} className="uploaddVideoPhoto">
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <AddNewTimelineData handleClose={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <EditTimelineData
            handleClose={handleCloseEdit}
            editData={editData}
            handleModalOpen={handleModalOpen}
          />
        </Modal.Body>
      </Modal>

      <DeleteModal
        handleDelete={handleDelete}
        id={ids}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
    </Fragment>
  );
};

export default Timeline;
