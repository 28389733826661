import React, { Fragment, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import img1B from "../../Assets/1Brown.png";
import img1W from "../../Assets/1White.png";
import img2B from "../../Assets/2Brown.png";
import img2W from "../../Assets/2White.png";
import img3B from "../../Assets/3Brown.png";
import img3W from "../../Assets/3White.png";
import img4B from "../../Assets/4Brown.png";
import img4W from "../../Assets/4White.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFaqLisiting } from "../../Redux/Actions/faq";

const FAQLIsting = () => {
  const { data } = useSelector((state) => state.FaqReducer);
  console.log(data);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getFaqLisiting());
  }, [dispatch]);
  return (
    <Fragment>
      <div className="container-fluid qrr myTributess">
        <div className="row noMArr fform tribute faq">
          <div className="col-sm-12 widthSeventy">
            <Accordion defaultActiveKey={0}>
              {data?.map((item, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header>
                    <div>
                      {i == 0 && <img src={img1B} alt="asd" />}
                      {i == 1 && <img src={img2B} alt="asd" />}
                      {i == 2 && <img src={img3B} alt="asd" />}
                      {i == 3 && <img src={img4B} alt="asd" />}
                    </div>
                    <div>{item?.Question}</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {i == 0 && <img src={img1W} alt="asd" />}
                      {i == 1 && <img src={img2W} alt="asd" />}
                      {i == 2 && <img src={img3W} alt="asd" />}
                      {i == 3 && <img src={img4W} alt="asd" />}
                    </div>
                    <div>{item?.Answer}</div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FAQLIsting;
