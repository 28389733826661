import { AiChat, useAsStreamAdapter } from '@nlux/react';
import React, { Fragment, useEffect } from 'react'
import { Send } from './Send';
import '@nlux/themes/nova.css';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMyDetails } from '../../Redux/Actions/auth';

const ChatbotModal = () => {
    const dispatch = useDispatch();

    const adapter = useAsStreamAdapter(Send, []);
    const { id } = useParams();
    const { data } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(getMyDetails({ id }));
    }, [dispatch, id]);
    return (
        <Fragment>
            <AiChat 
                adapter={ adapter }
                personaOptions={{
                    assistant: {
                        name: "Legacy Honored",
                        tagline: "Your Genius AI Assistant",
                        avatar: 'https://docs.nlkit.com/nlux/images/personas/albert.png'
                    },
                    user: {
                        name: data?.Name,
                        avatar: data?.ProfilePic
                    }
                }}
                conversationOptions={{ layout: 'bubbles' }}
                displayOptions={{ colorScheme: "light" }}
                composerOptions={{ placeholder: "Type your query" }}
            />
        </Fragment>
    )
}

export default ChatbotModal
