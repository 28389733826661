import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

const DescriptionModal = ({data}) => {
  const { t } = useTranslation();

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row uploadImageForm">
                    <div className="col-sm-12 uploadHeading">
                        <p>{t("DescriptionModal")}</p>
                    </div>

                    <div className="col-sm-12">
                        <p>{data?.Description}</p>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DescriptionModal
