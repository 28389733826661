import { Button } from "antd";
import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const CheckProfile = ({ profileData, isActive }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const handleRegister = () => {
    navigate(`/pages/?qrCodeId=${id}`);
  };

  const handleSignInDifferent = () => {
    navigate(`/?qrCodeId=${id}`);
  };

  const handleCreateAccount = () => {
    navigate(`/?qrCodeId=${id}`);
  };
  return (
    <>
      {profileData ? (
        <>
          <p className="noDataDescription">
            Sweet, looks like you're signed as {profileData?.Email}! Now you can
            register your QR Code.
          </p>
          <Button className="registerBtn" onClick={handleRegister}>
            Register
          </Button>
          <a className="signInDifferent" onClick={handleSignInDifferent}>
            Sign in with a different account
          </a>
        </>
      ) : (
        !isActive && (
          <>
            <p className="noDataDescription">
              Welcome to Legacy Honored! Looks like this qr code has not been
              registered. Let's start by creating your account.
            </p>
            <Button className="registerBtn" onClick={handleCreateAccount}>
              Create Account
            </Button>
            <a className="signInDifferent" onClick={handleSignInDifferent}>
              Sign In
            </a>
          </>
        )
      )}
    </>
  );
};

export default CheckProfile;
