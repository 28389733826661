import { Button, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { clearState } from "../../Redux/Reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const FormTwo = ({ onFinishStep2, onFinishFailed2 }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { error, success, loading } = useSelector((state) => state.user);
  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearState());
    }
    if (success) {
      message.success(success);
      navigate("/pages");

      dispatch(clearState());
    }
  }, [error, success]);
  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinishStep2}
        onFinishFailed={onFinishFailed2}
        autoComplete="off"
      >
        <div className="col-sm-12 passreset">
          <Form.Item
            name="password"
            label={t("Password")}
            rules={[
              {
                required: true,
                message: t("Please enter Password!"),
              },
            ]}
          >
            <Input.Password placeholder={t("Password")} />
          </Form.Item>
        </div>
        <div className="col-sm-12">
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? <Loader /> : t("Login")}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FormTwo;
