import { Button, Form, Input, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { postTimeLine } from "../../../Redux/Actions/timeLine";
import { clearState } from "../../../Redux/Reducers/timeLineReducer";
import { useParams } from "react-router-dom";
import CustomCalender from "../CustomCalender";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const AddNewTimelineData = ({ handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { postError, postSuccess, loading } = useSelector(
    (state) => state.timeline
  );
  const [selectedDate, setSelectedDate] = useState();
  const { id } = useParams();
  const onFinish = (values) => {
    values.Time = selectedDate;
    dispatch(postTimeLine({ id, values }));
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (postError) {
      message.error(postError);
      dispatch(clearState());
    }
    if (postSuccess) {
      message.success(postSuccess);
      dispatch(clearState());
      handleClose();
    }
  }, [postError, postSuccess]);
  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
        >
          <div className="row uploadImageForm">
            <div className="col-sm-12 uploadHeading">
              <p>{t("addNewTimeline")}</p>
            </div>

            <div className="col-sm-12 datee">
              <Form.Item name="date" label={t("eventDate")}>
                <DatePicker
                  value={selectedDate}
                  selected={selectedDate ? selectedDate : null}
                  isClearable
                  onChange={handleDateChange}
                  placeholderText={t("eventDate")}
                  dateFormat="MMMM d, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  dropdownMode="select"
                  renderCustomHeader={CustomCalender}
                />{" "}
              </Form.Item>
            </div>

            <div className="col-sm-12 videoLink">
              <Form.Item
                name="EventName"
                label={t("eventName")}
                rules={[
                  {
                    required: true,
                    message: `${t("Please input the event name!")}`,
                  },
                ]}
              >
                <Input placeholder={t("eventName")} />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item name="Description" label={t("description")}>
                <Input.TextArea rows={4} placeholder={t("description")} />
              </Form.Item>
            </div>

            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? <Loader /> : `${t("save")}`}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddNewTimelineData;
