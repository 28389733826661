import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postEmailFailure,
  postEmailStart,
  postEmailSuccess,
  postProfileFailure,
  postProfileStart,
  postProfileSuccess,
} from "../Reducers/postProfileReducer";
import Cookies from "js-cookie";
import axios from "axios";

export const postProfile = createAsyncThunk(
  "auth",
  async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(postProfileStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/SignUp`,
        values,
        config
      );
      const responseData = await response;
      Cookies.set("token", responseData.data.data);

      thunkAPI.dispatch(postProfileSuccess(responseData));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postProfileFailure(error.response.data.message));

      throw error;
    }
  }
);

export const postEmail = createAsyncThunk("auth", async (values, thunkAPI) => {
  try {
    thunkAPI.dispatch(postEmailStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}UserInfoRoutes/ExistEmail`,
      values,
      config
    );
    const responseData = await response;

    thunkAPI.dispatch(postEmailSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(postEmailFailure(error.response.data.message));

    throw error;
  }
});
